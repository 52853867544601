import axios from "axios";
import { useAuth } from "../context/useAuth";
import { backendPrefix } from "../utils/backendPrefix";

const useRefreshToken = () => {
  const { setAccessToken, setRole } = useAuth()
  const refresh = async() => {
    const response = await axios.get(
      backendPrefix + '/api/v1/admin/refresh',
      {
        withCredentials: true,
      }
    )
    if(response){
      setAccessToken(response.data?.token)
      setRole(response.data?.role)
    }
  }
  return refresh
}

export default useRefreshToken