import {
  HStack, Container, Divider, Text, Button,
  Heading,TableContainer,Table,Thead,Tbody,Td,Th,Tr, Select,
  Box,   Stat, StatLabel, StatNumber,StatHelpText, useInterval
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getCurrentEpoch, setToPreviousEpoch, getEpochOrderIds } from "../services/mpEpochService";
import { useAuth } from '../context/useAuth';

const Epochs = () => {
  const { accessToken } = useAuth();
  const [currentEpochId, setCurrentEpochId] = useState(-1);
  const [currentOrderIds, setCurrentOrderIds] = useState([]);
  const [prevOrderIds, setPrevOrderIds] = useState([]);

  const _getCurrentEpoch = async() => {
    if (!accessToken) return
    let {currentEpochId} = await getCurrentEpoch(accessToken)
    setCurrentEpochId(currentEpochId)
    const currentOrderIds = await getEpochOrderIds(accessToken, currentEpochId)
    setCurrentOrderIds(currentOrderIds.data)
    const prevEpochId = Number(currentEpochId) - 1
    const prevOrderIds = await getEpochOrderIds(accessToken, prevEpochId.toString())
    setPrevOrderIds(prevOrderIds.data)
  }

  const _setPreviousEpochId = async() => {
    if (confirm("Are you sure you want to set it to previous epoch?")){
      if (!accessToken) return
      const {result, status} = await setToPreviousEpoch(accessToken)
      if (status == 'Success'){
        _getCurrentEpoch()
      }
    }
  }

  useEffect(() => {
    _getCurrentEpoch()
  }, [])

  return(
  <>
    <Heading fontSize={"24px"} mt={5} mb={5}>
      Epochs
    </Heading>
    <TableContainer overflowY={"auto"} maxH="container.xl" mt={5}>
      <Table
        colorScheme="white"
        variant={"unstyled"}
        fontSize={"18px"}
        size={"sm"}
        mt={8}
      >
        <Thead>
          <Tr>
            <Th>Epoch Id</Th>
            <Th>No. Of Orders (Current)</Th>
            <Th>No. Of Orders (Prev)</Th>
            <Th color='red.400'>Set Previous</Th>
          </Tr>
        </Thead>
        <Tbody key={currentEpochId}>
          <Tr key={currentEpochId}>
            <Td>{currentEpochId}</Td>
            <Td>{currentOrderIds.length}</Td>
            <Td>{prevOrderIds.length}</Td>
            <Td>
              <Button colorScheme='red' fontSize = 'sm' onClick={() => _setPreviousEpochId()}>
                {" "}Set to previous Id{" "}
              </Button>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  </>
  )
}

export default Epochs;
