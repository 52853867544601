import axios from 'axios'
import { backendPrefix } from '../utils/backendPrefix'

export async function getMarkets(chainId: number) {
  return axios
  .get(backendPrefix + `/api/v1/markets?chainId=${chainId}`)
  .then(response => {
    return response.data
  })
  .catch(error => {
    console.log('Fetch mp markets error:', error)
  })
}