import {
  Container,
  Button,
  Heading,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Td,
  Th,
  Tr,
  useInterval,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { removeJob, getJobList, pauseOrResumeJob, resumeAllJobs, pauseAllJobs } from "../services/mpJobService";
import { useAuth } from '../context/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

const Jobs = () => {
  const [jobList, setJobList] = useState([]);
  const { accessToken } = useAuth();
  const refresh = useRefreshToken();

  useEffect(() => {
    _getJobList()
  }, []);

  useInterval(() => {
    _getJobList();
  }, 3000);

  const _getJobList = async() => {
    if(!accessToken) return
    const jobList = await getJobList(accessToken)
    if(jobList) setJobList(jobList)
    else await refresh()
  }

  const _removeJob = async (jobName:string) => {
    confirm(`Are you sure you want to remove ${jobName}?`)
    if(!accessToken) return
    await removeJob(jobName, accessToken).then(async(res) => {
      if (res.status === 200) {
        toast(`Job ${jobName} removed`, {
          type: "success",
          theme: "dark",
          position: "top-right",
          toastId: "removejobsuccess",
        });
      } else {
        toast(`Job ${jobName} failed to remove`, {
          type: "error",
          theme: "dark",
          position: "top-right",
          toastId: "removejobfail",
        });
        await refresh()
      }
    });
  };

  const _pauseOrResumeJob = async (jobName:string) => {
    confirm(`Are you sure you want to pause/resume ${jobName}?`)
    if(!accessToken) return
    await pauseOrResumeJob(jobName, accessToken).then(async(res) => {
      if (res.status === 200) {
        toast(`Job ${jobName} paused/resumed`, {
          type: "success",
          theme: "dark",
          position: "top-right",
          toastId: "togglejobsuccess",
        });
      } else {
        toast(`Job ${jobName} failed to pause/resume`, {
          type: "error",
          theme: "dark",
          position: "top-right",
          toastId: "togglejobfailed",
        });
        await refresh()
      }
    });
  };

  const _pauseAllJobs = async () => {
    confirm(`Are you sure you want to pause all jobs?`)
    if(!accessToken) return
    await pauseAllJobs(accessToken).then(async(res) => {
      if (res.status === 200) {
        toast(`All jobs paused!`, {
          type: "success",
          theme: "dark",
          position: "top-right",
          toastId: "toggleAllJobsSuccess",
        });
      } else {
        toast(`Failed to pause all jobs`, {
          type: "error",
          theme: "dark",
          position: "top-right",
          toastId: "toggleAllJobsFailed",
        });
        await refresh()
      }
    });
  };

  const _resumeAllJobs = async () => {
    confirm(`Are you sure you want to pause all jobs?`)
    if(!accessToken) return
    await resumeAllJobs(accessToken).then(async(res) => {
      if (res.status === 200) {
        toast(`All jobs resumed!`, {
          type: "success",
          theme: "dark",
          position: "top-right",
          toastId: "toggleAllJobsSuccess",
        });
      } else {
        toast(`Failed to resume all jobs`, {
          type: "error",
          theme: "dark",
          position: "top-right",
          toastId: "toggleAllJobsFailed",
        });
        await refresh()
      }
    });
  };



  return (
    <Container mt={8} minWidth={"container.lg"}>
      <Flex flexDir="row" justifyContent={'space-between'} alignItems='center'>
        <Heading fontSize={"24px"}>
          Job List
        </Heading>
        <Flex flexDir={'row'} justifyContent="flex-end" gap={4}>
          <Button fontSize={'13px'} colorScheme="red" onClick={_pauseAllJobs}> Pause All</Button>
          <Button fontSize={'13px'} colorScheme="whatsapp" onClick={_resumeAllJobs}> Resume All</Button>
        </Flex>
      </Flex>
      <Divider my={4}/>
      <TableContainer overflowY={"auto"} maxH="container.xl" mt={5}>
        <Table
          colorScheme="white"
          variant={"unstyled"}
          fontSize={"18px"}
          size={"sm"}
          mt={8}
        >
          <Thead>
            <Tr>
              <Th>Job Name</Th>
              <Th>Next Run</Th>
              <Th>Status</Th>
              <Th color='yellow.400'>Pause</Th>
              <Th color='red.400'>Stop</Th>
            </Tr>
          </Thead>
          {jobList?.map((job: any, i) => {
            return (
              <Tbody key={i}>
                <Tr key={i}>
                  <Td>{job.name}</Td>
                  <Td>{job.nextRun}</Td>
                  <Td color={job.paused? 'yellow.400' : 'green.400'}>{job.paused? 'Paused' : 'Running'}</Td>
                  <Td>
                    {job.paused
                    ? 
                    <Button colorScheme='green' fontSize = 'sm' onClick={() => _pauseOrResumeJob(job.name)}>
                      {" "}
                      Resume job{" "}
                    </Button>
                    :
                    <Button colorScheme='yellow' fontSize = 'sm' onClick={() => _pauseOrResumeJob(job.name)}>
                      {" "}
                      Pause job{" "}
                    </Button>
                    }
                  </Td>
                  <Td>
                    <Button colorScheme='red' fontSize = 'sm' onClick={() => _removeJob(job.name)}>
                      {" "}
                      Remove job{" "}
                    </Button>
                  </Td>
                </Tr>
              </Tbody>
            );
          })}
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Jobs;
