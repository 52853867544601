import {
  Text,
  Flex,
  Avatar,
  useColorModeValue,
  HStack,
  Spinner,
  Divider,
  useDisclosure,
  Collapse,
  AvatarBadge,
  useColorMode,
  SimpleGrid,
  GridItem,
  Link,
} from "@chakra-ui/react";
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
  PaginationSeparator
} from "@ajna/pagination";
import { CheckIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";
import { UserHookProps } from "../types";
import { truncateAddress } from "../utils/truncate";

const UserHookRecords = ({
  hooks,
  isOpenOrderTab
}: {
  hooks: UserHookProps[], 
  isOpenOrderTab: boolean, 
}) => {
  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages
  } = usePagination({
    limits: {inner: 2, outer: 2},
    pagesCount: Math.ceil(hooks?.length / 5),
    initialState: { currentPage: 1 },
  });

  const UserHook = ({
    hook,
  } : {
    hook: UserHookProps,
  }) => {
    const { isOpen, onToggle } = useDisclosure();
    return(
      <Flex flexDir={"column"}>
        <Flex
          fontSize={{base: "12px" , lg:'14px'}}
          flexDir='column'
          boxShadow='base' 
          border={'1px'} 
          borderColor="gray.500" 
          rounded="xl"
          my={{base: 2, lg: 4}}
          >
          <Flex 
            p={{base: 2, md: 4}}
            flexDir={'row'} 
            justifyContent={{base: "space-between", xl: "start"}}
            alignItems='center'
            >
            <HStack alignItems='center' gap={4} minW={{base: "0px", xl: "200px"}}>
              {!isOpenOrderTab ? (
                <Avatar bg={"teal.500"} width={8} height={8} icon={<CheckIcon width={4} height={4} color="white"/>}/>
              ) : (
                <Avatar bg={"orange.500"} width={8} height={8} icon={<Spinner width={4} height={4} color="white" speed='0.55s'/>}/>
              )}
              <Flex flexDir={'column'} alignItems={'flex-start'}>
                <Flex flexDir={'row'} gap={2} fontSize='12px'>
                  {!isOpenOrderTab
                  ? <Text fontWeight={"bold"} color={useColorModeValue('#43A047','#66BB6A')}>{"Completed"}</Text>
                  : hook.orderId && hook.orderId > 0
                    ? <Text fontWeight={"bold"} color={useColorModeValue('#FDD835','#FFEE58')}>{"Bridging to Dest. Chain"}</Text>
                    : hook.depositReceiptId && hook.depositReceiptId > 0
                    ? <Text fontWeight={"bold"} color={useColorModeValue('#E53935','#EF5350')}>{"Swap initiated"}</Text>
                    : <Text fontWeight={"bold"} color={useColorModeValue('#718096','#718096')}>{"Pending"}</Text>
                  }
                </Flex>
                <Text fontSize={11}>{`hook Id: ${hook.id}`}</Text>
              </Flex>
            </HStack>
            <HookDetails hook={hook}/>
            <Avatar justifySelf={"end"} bg={useColorModeValue("gray.200", "gray.400")} width={4} height={4} icon={isOpen? <ChevronUpIcon/> : <ChevronDownIcon/>} onClick={onToggle} display={{base: 'inherit', xl: "none"}}/>
          </Flex>
          <MobileHookDetails hook={hook} isOpen={isOpen}/>
        </Flex>
      </Flex>
    )
  }

  const HookDetails = ({hook} : {hook: UserHookProps}) => {
    const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
    const { colorMode } = useColorMode();
    const createdAt = new Date(hook.createdAt);
    return(
      <SimpleGrid width={"full"} columns={isOpenOrderTab? 10 : 14} alignItems={'center'} display={{base: "none", xl: "grid"}}>
        <GridItem colSpan={2}>
          <HStack px={2} spacing={4} justifyItems={'space-between'}>
            <Avatar bg={avatarBgColor} size={{base: "xs", sm:'sm'}} name={hook.sourceChain.name} src={colorMode === "light" ? hook.sourceChain.lightLogo : hook.sourceChain.darkLogo}>
              <AvatarBadge>
                <Avatar bg={avatarBgColor} size={"2xs"} name={hook.fromSym.name} src={hook.fromSym.logoUrl}/>
              </AvatarBadge>
            </Avatar>
            <Flex flexDir={'column'} alignItems='flex-start'>
              <Text as={'b'} fontSize="13px">{hook.sourceChain.name}</Text>
              <Text fontSize="12px" color={useColorModeValue('gray.600', 'gray.300')}>
                {Number(hook.pay)?.toLocaleString(undefined, {maximumFractionDigits: hook.fromSym.displayDecimal})} {hook.fromSym.symbol}
              </Text>
            </Flex>
          </HStack>
        </GridItem>
        <GridItem justifySelf={"start"}>
          <MdOutlineKeyboardDoubleArrowRight/>
        </GridItem>
        <GridItem colSpan={2}>
          <HStack px={2} spacing={4} justifyItems={'space-between'}>
            <Avatar bg={avatarBgColor} size={{base: "xs", sm:'sm'}} name={hook.destChain.name} src={colorMode === "light" ? hook.destChain.lightLogo : hook.destChain.darkLogo}>
              <AvatarBadge>
                <Avatar bg={avatarBgColor} size={"2xs"} name={hook.toSym.name} src={hook.toSym.logoUrl}/>
              </AvatarBadge>
            </Avatar>
            <Flex flexDir={'column'} alignItems='flex-start'>
              <Text as={'b'} fontSize="13px">{hook.destChain.name}</Text>
              <Text fontSize="12px" color={useColorModeValue('gray.600', 'gray.300')}>
                {!isOpenOrderTab && Number(hook.receive)?.toLocaleString(undefined, { maximumFractionDigits: hook.toSym.displayDecimal })} {hook.toSym.symbol}
              </Text>
            </Flex>
          </HStack>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir={'column'}>
            <Text fontSize={12} fontWeight={'semibold'}> Order Id </Text>
            <Text fontSize="12px" color={useColorModeValue('gray.600', 'gray.300')}> 
              {hook.orderId? hook.orderId : "--"}
            </Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex flexDir={'column'}>
            <Text fontSize={12} fontWeight={'semibold'}> Deposit Receipt Id</Text>
            <Text fontSize="12px" color={useColorModeValue('gray.600', 'gray.300')}> 
              {hook.depositReceiptId? hook.depositReceiptId: "--"}
            </Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex flexDir={'column'}>
            <Text fontSize={12} fontWeight={'semibold'}> Timestamp</Text>
            <Text fontSize={10} color={useColorModeValue('gray.600', 'gray.300')}>{createdAt.toLocaleDateString()}{" "}{createdAt.toLocaleTimeString()}</Text>
          </Flex>
        </GridItem>
        {!isOpenOrderTab &&
          <>
            <Flex flexDir={'column'}>
              <Text fontSize="12px" fontWeight={'semibold'}> Fees </Text>
              <HStack spacing={2} justifyItems={'space-between'}>
                <Text fontSize="12px" color={useColorModeValue('gray.600', 'gray.300')}> 
                  {Number(hook.fees)?.toLocaleString(undefined, { maximumFractionDigits: hook.toSym.displayDecimal })}
                  {" "}
                  {hook.toSym.symbol}
                </Text>
              </HStack>
            </Flex>
            <GridItem colSpan={2}>
              <Flex flexDir={'column'}>
                <Text fontSize={12} fontWeight={'semibold'}> Withdrawal Receipt Id </Text>
                <Text fontSize="12px" color={useColorModeValue('gray.600', 'gray.300')}> 
                  {hook.withdrawalReceiptId ? hook.withdrawalReceiptId : "--"}
                </Text>
              </Flex>
            </GridItem>
          </>
        }
      </SimpleGrid>
    )
  }

  const MobileHookDetails = ({hook, isOpen} : {hook: UserHookProps, isOpen: boolean}) => {
    const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
    return(
      <Collapse in={isOpen} animateOpacity>
        <Flex flexDir={'column'} p={4} gap={2}>
          <HStack justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>Source Chain:</Text>
            <HStack spacing={2} justifyItems={'space-between'}>
              <Avatar bg={avatarBgColor} size={{base: "xs", xl:'sm'}} name={hook.sourceChain.name} src={useColorModeValue(hook.sourceChain.lightLogo, hook.sourceChain.darkLogo)}/>
              <Text>{hook.sourceChain.name}</Text>
            </HStack>
          </HStack>
          <HStack justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>Destination Chain:</Text>
            <HStack spacing={2} justifyItems={'space-between'}>
              <Avatar bg={avatarBgColor} size={{base: "xs", xl:'sm'}} name={hook.destChain.name} src={useColorModeValue(hook.destChain.lightLogo, hook.destChain.darkLogo)}/>
              <Text>{hook.destChain.name}</Text>
            </HStack>
          </HStack>
          <HStack justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>Type:</Text>
            <Text> {hook.type} </Text>
          </HStack>
          <HStack justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>Pay:</Text>
            <HStack spacing={2} justifyItems={'space-between'}>
              <Avatar bg={avatarBgColor} size={{base: "xs", xl:'sm'}} name={hook.fromSym.symbol} src={hook.fromSym.logoUrl}/>
              <Text> 
                {Number(hook.pay)?.toLocaleString(undefined, { maximumFractionDigits: 3 })}
                {" "}
                {hook.fromSym.symbol}
              </Text>
            </HStack>
          </HStack>
          <HStack justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>Receive:</Text>
            <HStack spacing={2} justifyItems={'space-between'}>
              <Avatar bg={avatarBgColor} size={{base: "xs", xl:'sm'}} name={hook.toSym.symbol} src={hook.toSym.logoUrl}/>
              <Text> 
                {Number(hook.pay)?.toLocaleString(undefined, { maximumFractionDigits: 3 })}
                {" "}
                {hook.toSym.symbol}
              </Text>
            </HStack>
          </HStack>
          {!isOpenOrderTab &&
            <>
              <HStack justifyContent={'space-between'}>
                <Text fontWeight={'bold'}>Fees:</Text>
                <HStack spacing={2} justifyItems={'space-between'}>
                  <Avatar bg={avatarBgColor} size={{base: "xs", xl:'sm'}} name={hook.toSym.symbol} src={hook.toSym.logoUrl}/>
                  <Text> 
                    {Number(hook.fees)?.toLocaleString(undefined, { maximumFractionDigits: 6 })}
                    {" "}
                    {hook.toSym.symbol}
                  </Text>
                </HStack>
              </HStack>
              <HStack justifyContent={'space-between'}>
                <Text fontWeight={'bold'}>Transaction Hash:</Text>
                {hook.withdrawalTransactionHash && hook.withdrawalTransactionHash.slice(0,2) === "0x"
                  ? <Link 
                    isExternal={true}
                    href={
                      `${hook.destChain.blockExplorerUrl}/tx/${hook.withdrawalTransactionHash}`}
                    >
                      <Flex flexDir={'row'} alignItems='center' gap={2} color={useColorModeValue('gray.600', 'gray.300')}>
                        {truncateAddress(hook.withdrawalTransactionHash, 4)}
                        <FiExternalLink/>
                      </Flex>
                  </Link>
                  : <Text> -- </Text>
                  }
              </HStack>
            </>
          }
        </Flex>
      </Collapse>
    )
  }

  return(
    <>
      {hooks.length > 0
      ? <Flex flexDir={'column'} justifyContent="space-between" minHeight={'inherit'}>
          <Flex flexDir={'column'}>
            {hooks
            .slice((currentPage - 1) * 5, (currentPage - 1) * 5 + 5)
            .map((hook: UserHookProps, i) => {
              return (
                <UserHook
                  key={i}
                  hook={hook}
                />
              );
            })}
          </Flex>
          <Flex justifySelf={'flex-end'} flexDir='column' width={'full'}>
            <Divider/>
            <Pagination
              pagesCount={pagesCount}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            >
              <PaginationContainer
                display={'flex'}
                justifyContent={'center'}
                w={'full'}
                mt={4}
                mb={2}>
                <PaginationPrevious w="4rem" bgColor={'transparent'}> <ChevronLeftIcon/> </PaginationPrevious>
                <PaginationPageGroup separator={<PaginationSeparator/>} >
                  {pages?.map((page: number) => (
                    <PaginationPage 
                      key={`pagination_page_${page}`} 
                      page={page} 
                      w="3rem"
                      bgColor={'transparent'}
                      _current={{
                        bgColor: useColorModeValue("gray.200", "gray.600"),
                      }}
                    />
                  ))}
                </PaginationPageGroup>
                <PaginationNext w="4rem" bgColor={'transparent'}> <ChevronRightIcon/> </PaginationNext>
              </PaginationContainer>
            </Pagination>
          </Flex>
        </Flex>
      : <Flex my={4} flexDir={'row'} alignItems={'center'} justifyContent='center' width={'full'}>
        <span>Record not found</span>
      </Flex>
      }
    </>
  );
}

export default UserHookRecords;
