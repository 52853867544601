import {
  Text,
  Flex,
  Avatar,
  useColorModeValue,
  HStack,
  Grid,
  useDisclosure,
  Collapse,
} from "@chakra-ui/react";
import { FiExternalLink } from "react-icons/fi";
import { useEffect, useRef, useState } from "react";
import { MpReceiptProps } from "../types";
import { timeAgo } from "../utils/dates";
import { CheckIcon, ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@chakra-ui/icons";

const UserCompletedReceipts = ({
  displayChain,
  completedReceipts,
}: {
  displayChain: string,
  completedReceipts: MpReceiptProps[];
}) => {
  const [displayCompletedReceipts, setDisplayCompletedReceipts] = useState<MpReceiptProps[]>(completedReceipts)
  const refDate = useRef(new Date(0))

  useEffect(() => {
    if(displayChain !== 'Cross') {
      const filteredCompletedReceipts = completedReceipts.filter((receipt) => receipt.chain.name === displayChain)
      setDisplayCompletedReceipts(filteredCompletedReceipts)
    } else {
      setDisplayCompletedReceipts(completedReceipts)
    }
  }, [displayChain, completedReceipts])

  const CompletedReceipt = ({
    receipt,
    showDate
  } : {
    receipt: MpReceiptProps,
    showDate: boolean
  }) => {
    const { isOpen, onToggle } = useDisclosure();
    const createdAt = new Date(receipt.createdAt);
    const updatedAt = new Date(receipt.updatedAt);
    return(
      <Flex flexDir={'column'} >
        <Text 
          alignSelf={'flex-start'} 
          fontWeight={'extrabold'} 
          fontSize={{base: "13px" , lg:'14px'}}
          >{showDate? createdAt?.toDateString() : ""}</Text>
        <Flex
          fontSize={{base: "12px" , lg:'14px'}}
          flexDir='column'
          boxShadow='base' 
          border={'1px'} 
          borderColor={receipt.status.name === 'Completed'? "teal.500" : "red.400"}
          rounded="xl"
          my={{base: 2, lg: 4}}
          >
          <Flex 
            p={{base: 2, md: 4}}
            flexDir={'row'} 
            justifyContent='space-between' 
            alignItems='center'
            >
            <HStack alignItems='center' gap={4}>
              {receipt.status.name === 'Completed'
              ? <Avatar bg={"teal.500"} width={8} height={8} icon={<CheckIcon width={4} height={4} color="white"/>}/>
              : <Avatar bg={"red.400"} width={8} height={8} icon={<CloseIcon width={4} height={4} color="white"/>}/>
              }
              <Flex flexDir={'column'} alignItems={'flex-start'}>
                <HStack>
                  <Text fontWeight={'extrabold'}>
                    {receipt.type.name}
                    </Text>
                  <Text color={useColorModeValue('gray.600', 'gray.300')}>・{timeAgo(updatedAt)}</Text>
                </HStack>
                <Flex flexDir={'row'} gap={2} fontSize='13px'>
                  <Text>Status: </Text>
                  <Text fontWeight={'bold'} color={receipt.status.name === 'Completed'? "green.500" : "red.500"}>{receipt.status.name}</Text>
                </Flex>
              </Flex>
            </HStack>
            <ReceiptDetails receipt={receipt}/>
            <Avatar bg={useColorModeValue("gray.200", "gray.400")} width={4} height={4} icon={isOpen? <ChevronUpIcon/> : <ChevronDownIcon/>} onClick={onToggle} display={{base: 'inherit', lg: "none"}}/>
          </Flex>
          <MobileReceiptDetails receipt={receipt} isOpen={isOpen}/>
        </Flex>
      </Flex>
    )
  }

  const ReceiptDetails = ({receipt} : {receipt: MpReceiptProps}) => {
    const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
    return(
      <Grid templateColumns="repeat(3, 200px)" alignItems={'center'} display={{base: "none", xl: "grid"}}>
        <HStack px={2} spacing={4} justifyItems={'space-between'}>
          <Flex flexDir={'column'} alignItems='flex-start'>
            <Text as={'b'} fontSize="14px">{receipt.chain.name} {`(${receipt.id})`}</Text>
            <Text fontSize="12px" color={useColorModeValue('gray.600', 'gray.300')}> {receipt.route.desc} </Text>
          </Flex>
        </HStack>
        <HStack px={2} spacing={4} justifyItems={'space-between'}>
          <Avatar bg={avatarBgColor} size={{base: "xs", md:'sm'}} name={receipt.asset.symbol} src={receipt.asset.logoUrl}/>
          <Text fontWeight={'bold'}> 
            {Number(receipt.amount)?.toLocaleString(undefined, { maximumFractionDigits: 3 })}
            {" "}
            {receipt.asset.symbol}
          </Text>
        </HStack>
        <Flex flexDir={'column'} alignItems={'flex-start'}>
          <Text fontWeight={'bold'}> Transaction Hash </Text>
          {receipt.userTransactionHash.slice(0,2) === "0x"
          ?
            <Flex flexDir={'row'} alignItems='center' gap={2} color={useColorModeValue('gray.600', 'gray.300')}>
              {receipt.userTransactionHash}
              <FiExternalLink/>
            </Flex>
          : <Text> -- </Text>
          }
        </Flex>
      </Grid>
    )
  }

  const MobileReceiptDetails = ({receipt, isOpen} : {receipt: MpReceiptProps, isOpen:boolean}) => {
    const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
    return(
      <Collapse in={isOpen} animateOpacity>
        <Flex flexDir={'column'} p={4} gap={2}>
          <HStack justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>Chain:</Text>
            <HStack spacing={2} justifyItems={'space-between'}>
              <Text>{receipt.chain.name}</Text>
            </HStack>
          </HStack>
          <HStack justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>Route:</Text>
            <Text> {receipt.route.desc} </Text>
          </HStack>
          <HStack justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>Amount:</Text>
            <HStack spacing={2} justifyItems={'space-between'}>
              <Avatar bg={avatarBgColor} size={{base: "xs", xl:'sm'}} name={receipt.asset.symbol} src={receipt.asset.logoUrl}/>
              <Text> 
                {Number(receipt.amount)?.toLocaleString(undefined, { maximumFractionDigits: 3 })}
                {" "}
                {receipt.asset.symbol}
              </Text>
            </HStack>
          </HStack>
          <Flex flexDir='row' justifyContent={'space-between'} width='full'>
            <Text alignSelf={'flex-start'} fontWeight={'bold'} whiteSpace='nowrap'> Transaction Hash: </Text>
            {receipt.userTransactionHash.slice(0,2) === "0x"
            ?  <Flex flexDir={'row'} gap={2}>
                {receipt.userTransactionHash}
                <FiExternalLink/>
              </Flex>
            : <Text> -- </Text>
            }
          </Flex>
        </Flex>
      </Collapse>
    )
  }

  return(
    <>
      {displayCompletedReceipts.length > 0
      ?<Flex flexDir={'column'} justifyContent="space-between" minHeight={'inherit'}>
        <Flex flexDir={'column'}>
          {displayCompletedReceipts
          .map((receipt: MpReceiptProps, i) => {
            let showDate : boolean = false
            if(refDate.current.toDateString() !== new Date(receipt.createdAt).toDateString() || i === 0) {
              refDate.current = new Date(receipt.createdAt)
              showDate = true
            }
            return (
              <CompletedReceipt
                key={i}
                receipt={receipt}
                showDate={showDate}
              />
            );
          })}
        </Flex>
      </Flex>
      : <Flex my={4} flexDir={'row'} alignItems={'center'} justifyContent='center' width={'full'}>
        Receord not found
      </Flex>
      }
    </>
  );
}

export default UserCompletedReceipts;
