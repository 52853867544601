import {
  Box,
  Button,
  IconButton,
  useColorMode,
  useColorModeValue,
  HStack,
  Heading,
  Drawer,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/hooks";
import { useAuth } from "../context/useAuth";
import { logout } from "../services/authService";

export default function NavBar() {
  const navigate = useNavigate();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { accessToken, setAccessToken, role, setRole } = useAuth();

  const userLogout = async() => {
    setAccessToken(null);
    setRole("user")
    await logout()
    navigate('/login');
  };

  const NavItem = ({
    title,
    onClick,
    eligibleUser,
    color,
    variant,
  }: {
    title: string;
    onClick: () => void;
    eligibleUser: string[];
    color?: string;
    variant?: string;
  }) => {
    if (role && eligibleUser.includes(role)) {
      return (
        <Box textAlign="center">
          <Button
            colorScheme={color}
            variant={variant || "ghost"}
            onClick={onClick}
          >
            {title}
          </Button>
        </Box>
      );
    } else {
      return <></>
    }
  };

  function NavBarItems() {
    return (
      <>
        <NavItem
          title="Home"
          onClick={() => {
            navigate("/");
          }}
          eligibleUser={["moderator", "team", "user"]}
        />
        <NavItem
          title="User"
          onClick={() => {
            navigate("/user");
          }}
          eligibleUser={["moderator", "team"]}
        />
        <NavItem
          title="Orders"
          onClick={() => {
            navigate("/orders");
          }}
          eligibleUser={["team"]}
        />
        <NavItem
          title="Jobs"
          onClick={() => {
            navigate("/jobs");
          }}
          eligibleUser={["team"]}
        />
        <NavItem
          title="Epochs"
          onClick={() => {
            navigate("/epochs");
          }}
          eligibleUser={["team"]}
        />
        <NavItem
          title="Admin"
          onClick={() => {
            window.open("https://admin.ciderbook.com"), "_blank"
          }}
          eligibleUser={["team"]}
        />
        <NavItem
          title="Orderbooks"
          onClick={() => {
            navigate("/orderbooks");
          }}
          eligibleUser={["moderator", "team"]}
        />
        <NavItem 
          title="Logout" 
          onClick={userLogout} 
          eligibleUser={["moderator", "team", "user"]}
          color={"blue"} 
          variant={'solid'} 
          />
      </>
    );
  }

  return (
    <Box bg={useColorModeValue("gray.100", "gray.900")} px={4} maxW="full">
      <Box
        display="flex"
        flexDir="row"
        py="20px"
        px='30px'
        height="80px"
        alignItems="center"
        justifyContent="space-between"
      >
        <NavLink to={"/"}>
          <Heading fontSize="xl">MP Backend Console</Heading>
        </NavLink>
        {accessToken ? (
          <>
            <Box
              display={["block", "none"]}
              flexGrow="1"
              position="sticky"
              ml={8}
            >
              <IconButton
                display={{ base: "flex", md: "none" }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<HamburgerIcon />}
              />
              <Drawer
                placement="right"
                onClose={onClose}
                isOpen={isOpen}
                size="xs"
              >
                <DrawerOverlay />
                <DrawerContent>
                  <NavBarItems />
                </DrawerContent>
              </Drawer>
            </Box>
            <HStack
              display={["none", "flex"]}
              pl="50px"
              spacing={5}
              justifyContent="space-between"
            >
              <NavBarItems />
            </HStack>
          </>
        ) : (
          <HStack pl="20px" justifySelf="end">
            <NavItem
              title="Login"
              onClick={() => {
                navigate("/login");
              }}
              eligibleUser={["moderator", "team", "user"]}
              color="green"
              variant="solid"
            />
          </HStack>
        )}
      </Box>
    </Box>
  );
}
