import {
  Heading,
  Text,
  Flex,
  useColorModeValue,
  Box,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  FormLabel,
  HStack,
  Input,
  VStack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  TabPanel,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ethers } from "ethers";
import { getUserBalance, getUserOrder, getUserReceipts } from "../services/mpAccountService";
import { BalanceByChainProps, BalanceBySymProps, MpReceiptProps, UserHookProps } from "../types";
import OrderHistory from "../components/OrderHistory";
import UserBalances from "../components/UserBalances";
import UserTransfers from "../components/UserTransfers";
import { chainIds } from "../utils/chainIds";
import UserHooks from "../components/UserHooks";
import { getUserHooks } from "../services/mpHookService";

const User = () => {
  const [displayChain, setDisplayChain] = useState<string>("Cross");
  const textColor = useColorModeValue("gray.600", "gray.400");
  const [address, setAddress] = useState("");
  const [balancesBySym, setBalancesBySym] = useState<BalanceBySymProps>({});
  const [balancesByChain, setBalancesByChain] = useState<BalanceByChainProps>({});
  const [totalAssetValue, setTotalAssetValue] = useState<number>(0);
  const [assetValueByChain, setAssetValueByChain] = useState<number>(0);
  const [openOrderHist, setOpenOrderHist] = useState([]);
  const [settledOrderHist, setSettledOrderHist] = useState([]);
  const [openHooks, setOpenHooks] = useState<UserHookProps[]>();
  const [closedHooks, setClosedHooks] = useState<UserHookProps[]>();
  const [pendingReceipts, setPendingReceipts] = useState<MpReceiptProps[]>([]);
  const [completedReceipts, setCompletedReceipts] = useState<MpReceiptProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const tabBgColor = useColorModeValue("gray.200", "gray.800");
  const selectedTabBgColor = useColorModeValue("gray.100", "gray.700");
  const selectedTabFontColor = useColorModeValue("cyan.600", "cyan.400");
  
  useEffect(() => {
    if (displayChain !== "Cross") {
      if (
        balancesByChain[displayChain] &&
        balancesByChain[displayChain].length > 0
      ) {
        const assetValue = balancesByChain[displayChain].reduce(
          (acc, current) => acc + current.assetValue,
          0
        );
        setAssetValueByChain(assetValue);
      }
    } else {
      setAssetValueByChain(totalAssetValue);
    }
  }, [displayChain, totalAssetValue]);

  const _getUserData = () => {
    setIsLoading(true);
    try {
      getUserBalance(address)
        .then((res: any) => {
          console.log(res)
          assignBalanceData(res.balancesBySym, res.balancesByChain)
          setTotalAssetValue(res.totalAssetValue);
        })
        .catch((err) => {
          console.log(err);
          setBalancesBySym({});
          setBalancesByChain({});
          setTotalAssetValue(0);
        });

      getUserOrder(address)
        .then((res: any) => {
          setOpenOrderHist(res.open);
          setSettledOrderHist(res.historic);
        })
        .catch(() => {
          setOpenOrderHist([]);
          setSettledOrderHist([]);
        });
      
      getUserHooks(address)
        .then((res:any) => {
          setOpenHooks(res.open)
          setClosedHooks(res.closed)
        })

        getUserReceipts(address)
        .then((res: any) => {
          setPendingReceipts(res.pendingReceipts);
          setCompletedReceipts(res.completedReceipts);
        })
        .catch(() => {
          setPendingReceipts([]);
          setCompletedReceipts([]);
        });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  function assignBalanceData(balancesBySymData: BalanceBySymProps, balancesByChainData: BalanceByChainProps){
    const initialBalancesBySym: BalanceBySymProps = {
      'ETH': {
        balance: 0,
        pendingTxnTotal: 0,
        netBalance: 0,
        assetValue: 0,
        chainNetBalances: [{
          chainId: chainIds[0].chainId,
          netBalance: 0
        }]
      },
      'USDC': {
        balance: 0,
        pendingTxnTotal: 0,
        netBalance: 0,
        assetValue: 0,
        chainNetBalances: [{
          chainId: chainIds[0].chainId,
          netBalance: 0
        }]
      },
      'USDT': {
        balance: 0,
        pendingTxnTotal: 0,
        netBalance: 0,
        assetValue: 0,
        chainNetBalances: [{
          chainId: chainIds[0].chainId,
          netBalance: 0
        }]
      }
    }
    let initialChainBalance = Object.keys(initialBalancesBySym).map((sym) => {
      return {
        symbol: sym,
        refAssetPairId: 0,
        balance: 0,
        pendingTxnTotal: 0,
        netBalance: 0,
        assetValue: 0
      }
    })
    let initialBalancesByChain : BalanceByChainProps = {}
    for(let i = 0; i < chainIds.length; i++){
      initialBalancesByChain[chainIds[i].shortName] = initialChainBalance
    }
    let balancesByChainResult = JSON.parse(JSON.stringify(initialBalancesByChain))
    let balancesBySymResult = JSON.parse(JSON.stringify(initialBalancesBySym))
    //assign balance by sym
    const currentSyms = Object.keys(initialBalancesBySym)
    const currentChains = Object.keys(initialBalancesByChain)
    for(const sym of currentSyms){
      if(balancesBySymData.hasOwnProperty(sym)){
        balancesBySymResult[sym] = balancesBySymData[sym]
      }
    }
    //assign balance by chain
    for(const chain of currentChains){
      if(balancesByChainData.hasOwnProperty(chain)){
        // chain exists; check if symbol exists in the chain data fetched
        for(let i = 0; i < initialBalancesByChain[chain].length; i++){
          const symbolData = initialBalancesByChain[chain][i]
          const portfolioDataSymRecord = balancesByChainData[chain].find((data: any) => data.symbol === symbolData.symbol)
          if(portfolioDataSymRecord){
            //overwriting record
            balancesByChainResult[chain][i] = portfolioDataSymRecord
          }
        }
      }
    }
    //assigning records
    setBalancesBySym(balancesBySymResult);
    setBalancesByChain(balancesByChainResult);
  }

  const TabLabel = ({label}:{label:string}) => (
    <Tab
      width={{ base: "120px", lg: "150px" }}
      whiteSpace="nowrap"
      fontSize={{ base: "12px", lg: "13px" }}
      bgColor={tabBgColor}
      _selected={{
        color: selectedTabFontColor,
        bg: selectedTabBgColor,
        fontWeight: "bold",
      }}
      roundedTop="md"
      border={0}
    >
      {label}
    </Tab>
  )
  return (
    <>
      <HStack alignItems={"end"}>
        <VStack width={"full"} alignItems="left">
          <FormLabel mt={10}> Search User: </FormLabel>
          <Input
            type="text"
            placeholder="address"
            onChange={(e) =>
              setAddress(ethers.utils.getAddress(e.target.value))
            }
          />
        </VStack>
        <Button
          width="200px"
          colorScheme="twitter"
          mt={4}
          onClick={_getUserData}
        >
          {" "}
          Pick User{" "}
        </Button>
      </HStack>
      <Heading fontSize={"14px"} fontWeight="extrabold" my={4} color="cyan.500">
        {" "}
        {"User Balances"}{" "}
      </Heading>
      {/* User Balance section */}
      <Flex
        border={"2px"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        my={4}
        width={"full"}
        gap={4}
      >
        <Flex flexDir={"column"} alignItems="center" my={4}>
          <Text fontSize={"lg"} color={textColor}>
            {" "}
            TOTAL PORTFOLIO VALUE{" "}
          </Text>
          <Heading>
            {" "}
            $
            {assetValueByChain?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}{" "}
          </Heading>
          <Text as={"i"} color={textColor} fontSize={"10px"}>
            {" "}
            Updated as at {new Date().toLocaleTimeString()}
          </Text>
        </Flex>
        <Flex flexDir="column" gap={4} width="100%">
          <Flex
            flexDir={"row"}
            justifyContent={{ base: "center" }}
            alignItems={"center"}
            gap={4}
          >
            <Text fontSize={"14px"} fontWeight="bold">
              Select chain:
            </Text>
            <Box
              className="pulse"
              borderRadius={"5px"}
              width={{ base: "60%", xl: "30%" }}
            >
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  width="full"
                  fontSize={{ base: "12px", lg: "14px" }}
                  color={"white"}
                  fontWeight={"bold"}
                >
                  {" "}
                  {displayChain}{" "}
                </MenuButton>
                <MenuList
                  fontSize={{ base: "12px", lg: "14px" }}
                  fontWeight={"bold"}
                >
                  <MenuOptionGroup
                    defaultValue={displayChain}
                    type="radio"
                    onChange={(chain) => {
                      if (typeof chain === "string") {
                        setDisplayChain(chain);
                      }
                    }}
                  >
                    <MenuItemOption value={"Cross"}>
                      {" "}
                      Cross-rollups{" "}
                    </MenuItemOption>
                    {Object.keys(balancesByChain).map((chain, i) => {
                      return (
                        <MenuItemOption key={i} value={chain}>
                          {" "}
                          {chain}{" "}
                        </MenuItemOption>
                      );
                    })}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
          <UserBalances
            userAddress={address}
            displayChain={displayChain}
            balancesBySym={balancesBySym}
            balancesByChain={balancesByChain}
          />
        </Flex>
      </Flex>
      <Tabs
        variant="enclosed-colored"
        isFitted
        >
        <TabList>
          <TabLabel label={"User Hooks"}/>
          <TabLabel label={"User Orders"}/>
          <TabLabel label={"User Receipts"}/>
        </TabList>
        <TabPanels>
           {/* Hooks section */}
          <TabPanel>
            <UserHooks openHooks={openHooks} closedHooks={closedHooks}/>
          </TabPanel>
          {/* Orders section */}
          <TabPanel>
            <OrderHistory
              openOrderRows={openOrderHist}
              historicOrderRows={settledOrderHist}
            />
          </TabPanel>
          {/* Receipts section*/}
          <TabPanel>
            <UserTransfers displayChain={displayChain} pendingReceipts={pendingReceipts} completedReceipts={completedReceipts}/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default User;
