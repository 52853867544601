import { Image, Center, Container, Heading } from "@chakra-ui/react"

const Home = () => {
  return(
    <Container alignItems='center' justifyContent='center' display='flex' minHeight='60vh' flexDir='column'>
      <Center>
        <Image boxSize= '100px' src = {process.env.PUBLIC_URL +'/logo.png'} alt='MES logo'/>
      </Center>
      <Center>
        <Heading size='md'  mt={10}>
          Welcome to Mes Protocol Backend Console
        </Heading>
      </Center>
    </Container>
  )
}

export default Home