import {
  Button, FormLabel, Input, HStack, VStack, Container, Divider,
  Heading,TableContainer,Table,Thead,Tbody,Td,Th,Tr, Select,
  Box,   Stat, StatLabel, StatNumber,StatHelpText, useInterval
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getSettledOrders } from "../services/mpOrderService";
import { ethers } from "ethers";
import { subFixed, addFixed} from "../utils/numbers"
import { useAuth } from '../context/useAuth';

const Order = () => {
  const { accessToken } = useAuth();
  let mmBotAddresses:string[]
  if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test'){
    mmBotAddresses = ['0x36615Cf349d7F6344891B1e7CA7C72883F5dc049']
  }else{
    mmBotAddresses = ['0xF2c54C193155F47EA3d2C84eF1a7B12EcCF84ec3', 
      '0xde037EC0CcC3cf505B0b04AfEe759195D2252dA4',
      '0x12d8f62f0306Fe237Aba5f1163Db1623EEFDc23e'
    ]
  }

  let todayDate = new Date()
  todayDate.setTime(todayDate.getTime() + 8 * 60000);
  const mm = todayDate.getMonth() + 1
  let mmStr:string
  if (mm < 10){
    mmStr = '0' + mm.toString()
  }else{
    mmStr = mm.toString()
  }
  const today = todayDate.getFullYear() + '-' + mmStr + '-' + ("0" + todayDate.getDate()).slice(-2)

  const [addresses, setAddresses] = useState(mmBotAddresses);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [pAndL, setPAndL] = useState<{[key:string]:number}>({});
  const [assetPairs, setAssetPairs] = useState<string[]>([])
  const [onlyShowAssetPair, setOnlyShowAssetPair] = useState('');

  const [allOrders, setAllOrders] = useState<any[]>([])
  const [pairTradeOrders, setPairTradeOrders] = useState<any[]>([])

  const calculatePAndL = (results:any) => {
    resetPAndL()
    for (let idx in results.orders){
      const order = results.orders[idx]
      if (order.orderDirection == 'Buy'){
        pAndL[order.baseSym] = addFixed((pAndL[order.baseSym] || 0), order.filledBaseAmount)
        pAndL[order.quoteSym] = subFixed((pAndL[order.quoteSym] || 0), order.filledQuoteAmount)
      }else{
        pAndL[order.baseSym] = subFixed((pAndL[order.baseSym] || 0), order.filledBaseAmount)
        pAndL[order.quoteSym] = addFixed((pAndL[order.quoteSym] || 0), order.filledQuoteAmount)
      }
    }

    for (let jdx in results.pairTradeOrders){
      const pto = results.pairTradeOrders[jdx]
      pAndL[pto.costCurr] = subFixed((pAndL[pto.costCurr] || 0), Number(pto.cost))
      pAndL[pto.earnCurr] = addFixed((pAndL[pto.earnCurr] || 0), Number(pto.qty))
    }
  }

  const resetPAndL = () => {
    const keys = Object.keys(pAndL)
    for (let idx in keys){
      delete pAndL[keys[idx]]
    }
  }

  const resetAllOrders = () => {
    allOrders.splice(0, allOrders.length)
    pairTradeOrders.splice(0, pairTradeOrders.length)
  }

  const _getSettledOrders = async() => {
    if(!accessToken) return
    resetAllOrders()
    let results:{orders:any[], pairTradeOrders:any[]} = {orders:[], pairTradeOrders:[]}
    for (let idx in addresses){
      let orders = await getSettledOrders(accessToken, addresses[idx], startDate, endDate)
      results.orders = results.orders.concat(orders.orders)
      //We only record binance orders for one time
      if (Number(idx) == 0){
        results.pairTradeOrders = orders.pairTradeOrders
      }
    }
    setAllOrders(results.orders)
    const targetAssetPairs = results.orders.map((o:any) => o.assetPair)
    let uniquePairs:string[] = targetAssetPairs.filter((value:string, index:number, array:string[]) => array.indexOf(value) === index);
    setAssetPairs(uniquePairs)
    setPairTradeOrders(results.pairTradeOrders)
    calculatePAndL(results)
  }

  useEffect(() => {
    _getSettledOrders()
  }, [])

  // useInterval(() => {
  //   _getSettledOrders()
  // }, 30000)

  return(
  <>
    <Heading fontSize={"24px"} mt={5} mb={5}>
      MP MMBot Orders
    </Heading>
    <HStack alignItems={"end"}>
      <HStack width={"full"} alignItems="left">
        <Input
          type="text"
          placeholder="0x...."
          defaultValue={mmBotAddresses.join(',')}
          onChange={(e) => setAddresses(e.target.value.split(','))}
        />
        <Input
          type="date"
          defaultValue={today}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <Input
          type="date"
          defaultValue={today}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <Select placeholder='All' onChange={(e) => setOnlyShowAssetPair(e.target.value)}>
          {assetPairs.map((assetPair:string, i) => {
              return (
                <option value={assetPair}>{assetPair}</option>
              )
            })
          }
        </Select>
      </HStack>
      <Button
        width="200px"
        colorScheme="twitter"
        onClick={_getSettledOrders}
      >
        {" "}
        Update{" "}
      </Button>
    </HStack>
    <HStack alignItems="left" pt={5} pb={5} spacing={10}>
      {Object.keys(pAndL).length > 0 ? (
          Object.keys(pAndL).map((key:string, i) => {
            return (
              <HStack alignItems="left">
                <Stat>
                  <StatLabel>{key}</StatLabel>
                  <StatNumber>{pAndL[key]}</StatNumber>
                </Stat>
              </HStack>
            )
          })
      ):(<>No Trx Found</>)}

    </HStack>
    <TableContainer
      overflowY={'auto'}
      maxH= 'container.xl'
    >
      <Table
        colorScheme="white"
        variant={"unstyled"}
        fontSize={"18px"}
        size={"sm"}
        ml={-4}
      >
        <Thead>
          <Tr>
            <Th>Timestamp</Th>
            <Th>Order Id</Th>
            <Th>Pair</Th>
            <Th>Direction</Th>
            <Th>Status</Th>
            <Th>Type</Th>
            <Th>Price</Th>
            <Th>Pay</Th>
            <Th>Receive</Th>
            <Th>Filled Price</Th>
          </Tr>
        </Thead>
        {allOrders.length == 0? (
          <Tbody></Tbody>
        ):(
          allOrders?.map((hist: any, i) => {
            const timestamp = new Date(hist.createdAt)
            if (onlyShowAssetPair.length == 0 || onlyShowAssetPair == hist.assetPair){
              //show everything
              return (
                <Tbody key={hist.id} >
                  <Tr key={hist.id}>
                    <Td>{timestamp.toLocaleString()}</Td>
                    <Td>{hist.id}</Td>
                    <Td>{hist.assetPair}</Td>
                    <Td color = {
                      hist.orderDirection === 'Buy'
                      ? 'green.300'
                      : 'red.300'
                      }>{hist.orderDirection}</Td>
                    <Td color = {
                      hist.status === 'Open'
                      ? 'green.400'
                      : hist.status === 'Partial'
                        ? 'yellow.400'
                        : hist.status === 'Filled'
                          ? 'cyan.200'
                          : hist.status === 'Settled'
                            ? 'cyan.400'
                            : 'red.400'
                    }>{hist.status}</Td>
                    <Td>{hist.type}</Td>
                    <Td>{hist.price}</Td>
                    <Td>{`${hist.orderDirection === 'Buy'? hist.filledQuoteAmount : hist.filledBaseAmount} / ${hist.payAmount}`}</Td>
                    <Td>{`${hist.orderDirection === 'Buy'? hist.filledBaseAmount : hist.filledQuoteAmount} / ${hist.receiveAmount}`}</Td>
                    <Td>{hist.filledPrice}</Td>
                  </Tr>
                </Tbody>
              );
            }
          })
        )}

        {pairTradeOrders.length == 0? (
            <Tbody></Tbody>
          ):(
            pairTradeOrders?.map((pto: any, j) => {
              const timestamp = new Date(Number(pto.executedAt))
              const [baseCurr, quoteCurr] = pto.assetPair.split('-')
              const orderDirection = (pto.costCurr == quoteCurr)? "Buy":"Sell"
              if (onlyShowAssetPair.length == 0 || onlyShowAssetPair == pto.assetPair){
                return (
                  <Tbody key={pto.pairTradeOrderId}>
                    <Tr key={pto.pairTradeOrderId}>
                      <Td>{timestamp.toLocaleString()}</Td>
                      <Td>{pto.pairTradeOrderId}</Td>
                      <Td>{pto.assetPair}</Td>
                      <Td color = {
                        orderDirection === 'Buy'
                        ? 'green.300'
                        : 'red.300'
                        }>{orderDirection}</Td>
                      <Td></Td>
                      <Td>{pto.exchange}</Td>
                      <Td></Td>
                      <Td>{pto.cost}</Td>
                      <Td>{pto.qty}</Td>
                      <Td></Td>
                    </Tr>
                  </Tbody>
                );
              }
            })
        )}

      </Table>
    </TableContainer>
  </>
  )
};

export default Order;
