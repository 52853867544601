import axios from 'axios'
import { backendPrefix } from '../utils/backendPrefix'

export async function getCachedOrderbook(token:string) {
  return axios
  .get(backendPrefix + '/api/v1/orders/orderbooks', {headers: {
        'Authorization' : `Bearer ${token}`
      }
    }).then(response => {
    return response.data
  })
  .catch(error => {
    console.log('Fetch orderbooks error:', error)
  })
}
