import axios from 'axios'
import { backendPrefix } from '../utils/backendPrefix'
import { SetPreviousEpochResponse } from '../types'
import { AxiosError } from "axios";

export async function getCurrentEpoch(token:string) {
  return axios
  .get(backendPrefix + '/api/v1/epochs/current', {headers: {
        'Authorization' : `Bearer ${token}`
      }
    }).then(response => {
    return response.data
  })
  .catch(error => {
    console.log('Fetch all accounts error:', error)
  })
}

export async function setToPreviousEpoch(token:string):Promise<SetPreviousEpochResponse>{
  return axios
  .put(backendPrefix + '/api/v1/epochs/previousEpochId', {}, {headers: {
        'Authorization' : `Bearer ${token}`
      }
    }).then(response => {
    return {status:"Success", result:response}
  })
  .catch(error => {
    console.log('Set to previous epoch Failed. Error:', error)
    return {status:"Failed", result:error}
  })
}

export async function getEpochOrderIds(token:string, epochId:string){
  let paramsInput:{[key:string]:string} = {epochId:epochId}
  return axios
  .get(backendPrefix + '/api/v1/epochs/epochOrders', {headers: {
        'Authorization' : `Bearer ${token}`
      }, params:paramsInput
    }).then(response => {
    return response.data
  })
  .catch(error => {
    console.log('Fetch all accounts error:', error)
  })
}
