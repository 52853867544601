import { useMemo, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  FormControl,
  FormLabel,
  Input,
  Img,
  Spinner,
  useColorModeValue,
  Button,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Text
} from "@chakra-ui/react";
import { login } from "../services/authService";
import { useAuth } from "../context/useAuth";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setAccessToken, setRole } = useAuth();

  const navigatePathname = useMemo(() => {
    const state = location.state as { from: Location };
    if (state && state.from) {
      return state.from;
    }
    return "/";
  }, [location]);

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await login(username, password);
      if (response) {
        if (response.status === 200) {
          const accessToken = response?.token;
          const role = response?.role;
          setAccessToken(accessToken);
          setRole(role)
          setLoading(false);
          navigate(navigatePathname, { replace: true });
        } else {
          let dbResponse = response.message;
          setLoading(false);
          setMessage(dbResponse);
        }
      }
    } catch (error) {
      setLoading(false);
      setMessage("Error, please contract dev");
    }
  };

  return (
    <Container mt={10} mb={20} centerContent={true}>
      <Box
        minH={"400px"}
        maxW={"400px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.900")}
        rounded={"lg"}
        boxShadow={"2xl"}
        overflow={"hidden"}
      >
        <Img
          mt={10}
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />
        <Box p={6}>
          <form onSubmit={handleLogin}>
            <FormControl id="username" isRequired>
              <FormLabel>Username</FormLabel>
              <Input
                type="text"
                name="username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </FormControl>
            <Text
              textAlign='end'
              fontSize='xs'
              color = 'blue.100'
              my={4}
              onClick={() => navigate('/changepw')}
              cursor='pointer'
              > Change Password</Text>
            <Center mt={4}>
              <Button disabled={loading} onClick={handleLogin}>
                {loading && <Spinner />}
                Login
              </Button>
            </Center>
          </form>

          {message && (
            <Alert mt={5} status="error">
              <AlertIcon />
              <Box flex="1">
                <AlertTitle>Error:</AlertTitle>
                <AlertDescription display="block">{message}</AlertDescription>
              </Box>
              <CloseButton
                position="absolute"
                right="8px"
                top="8px"
                onClick={() => setMessage("")}
              />
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
