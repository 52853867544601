import {
  Text,
  Avatar,
  Flex,
  useColorModeValue,
  HStack,
  Spinner,
  Grid,
  useDisclosure,
  Collapse,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { MpReceiptProps } from "../types";
import { timeAgo } from "../utils/dates";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

const UserPendingReceipts = ({
  displayChain,
  pendingReceipts,
}: {
  displayChain: string,
  pendingReceipts: MpReceiptProps[];
}) => {
  const [displayPendingReceipts, setDisplayPendingReceipts] = useState<MpReceiptProps[]>(pendingReceipts)
  const refDate = useRef(new Date(0))

  useEffect(() => {
    if(displayChain !== 'Cross') {
      const filteredPendingReceipts = pendingReceipts.filter((receipt) => receipt.chain.name === displayChain)
      setDisplayPendingReceipts(filteredPendingReceipts)
    } else {
      setDisplayPendingReceipts(pendingReceipts)
    }
  }, [displayChain, pendingReceipts])

  const PendingReceipt = ({
    receipt,
    showDate
  } : {
    receipt: MpReceiptProps,
    showDate: boolean
  }) => {
    const { isOpen, onToggle } = useDisclosure();
    const createdAt = new Date(receipt.createdAt);
    const updatedAt = new Date(receipt.updatedAt);

    return(
      <Flex flexDir={"column"}>
        <Text 
          alignSelf={'flex-start'} 
          fontWeight={'extrabold'}
          fontSize={{base: "13px" , lg:'14px'}}
          >{showDate? createdAt?.toDateString() : ""}</Text>
        <Flex
          fontSize={{base: "12px" , lg:'14px'}}
          flexDir='column'
          boxShadow='base' 
          border={'1px'} 
          borderColor="gray.500" 
          rounded="xl"
          my={{base: 2, lg: 4}}
          >
          <Flex 
            p={{base: 2, md: 4}}
            flexDir={'row'} 
            justifyContent='space-between'
            alignItems='center'
            >
            <HStack alignItems='center' gap={4}>
              <Avatar bg={"orange.500"} width={8} height={8} icon={<Spinner width={4} height={4} color="white" speed='0.55s'/>}/>
              <Flex flexDir={'column'} alignItems={'flex-start'}>
                <HStack>
                  <Text fontWeight={'extrabold'}>{receipt.type.name} {`(${receipt.id})`}</Text>
                  <Text color={useColorModeValue('gray.600', 'gray.300')}>・{timeAgo(updatedAt)}</Text>
                </HStack>
                <Flex flexDir={'row'} gap={2} fontSize='13px'>
                  <Text>Status: </Text>
                  <Text fontWeight={'bold'}>{receipt.status.name}</Text>
                </Flex>
              </Flex>
            </HStack>
            <ReceiptDetails receipt={receipt}/>
            <Avatar bg={useColorModeValue("gray.200", "gray.400")} width={4} height={4} icon={isOpen? <ChevronUpIcon/> : <ChevronDownIcon/>} onClick={onToggle} display={{base: 'inherit', lg: "none"}}/>
          </Flex>
          <MobileReceiptDetails receipt={receipt} isOpen={isOpen}/>
          {receipt.status.name === 'Pending User Action'
          ? <Flex 
            p={4}
            flexDir={{base: "column", lg: "row"}} 
            gap={{base: 4, lg: 0}}
            alignItems={"center"}
            bgColor={useColorModeValue("blue.50", "blue.800")} 
            justifyContent={'space-between'}
            roundedBottom="xl"
            >
            <Text fontWeight={'bold'}>Please click on "Claim Tokens" to finalize transaction</Text>
          </Flex>
          :<></>
          }
        </Flex>
      </Flex>
    )
  }

  const ReceiptDetails = ({receipt} : {receipt: MpReceiptProps}) => {
    const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
    return(
      <Grid templateColumns="repeat(2, 200px)" alignItems={'center'} display={{base: "none", lg: "grid"}}>
        <HStack px={2} spacing={4} justifyItems={'space-between'}>
          <Flex flexDir={'column'} alignItems='flex-start'>
            <Text as={'b'} fontSize="14px">{receipt.chain.name}</Text>
            <Text fontSize="12px" color={useColorModeValue('gray.600', 'gray.300')}> {receipt.route.desc} </Text>
          </Flex>
        </HStack>
        <HStack px={2} spacing={4} justifyItems={'space-between'}>
          <Avatar bg={avatarBgColor} size={{base: "xs", md:'sm'}} name={receipt.asset.symbol} src={receipt.asset.logoUrl}/>
          <Flex flexDir={'column'} alignItems='flex-start'>
            <Text fontWeight={'bold'}> 
              {Number(receipt.postFeeFinalAmount)?.toLocaleString(undefined, { maximumFractionDigits: 3 })}
              {" "}
              {receipt.asset.symbol}
            </Text>
            <Text fontSize="12px" color={useColorModeValue('gray.600', 'gray.300')}> Fee: {receipt.fee} {" "} {receipt.asset.symbol}</Text>
          </Flex>
        </HStack>
      </Grid>
    )
  }

  const MobileReceiptDetails = ({receipt, isOpen} : {receipt: MpReceiptProps, isOpen: boolean}) => {
    const avatarBgColor = useColorModeValue('gray.200', 'gray.800');
    return(
      <Collapse in={isOpen} animateOpacity>
        <Flex flexDir={'column'} p={4} gap={2}>
          <HStack justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>Chain:</Text>
            <HStack spacing={2} justifyItems={'space-between'}>
              <Text>{receipt.chain.name}</Text>
            </HStack>
          </HStack>
          <HStack justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>Route:</Text>
            <Text> {receipt.route.desc} </Text>
          </HStack>
          <HStack justifyContent={'space-between'}>
            <Text fontWeight={'bold'}>Amount:</Text>
            <HStack spacing={2} justifyItems={'space-between'}>
              <Avatar bg={avatarBgColor} size={{base: "xs", xl:'sm'}} name={receipt.asset.symbol} src={receipt.asset.logoUrl}/>
              <Text> 
                {Number(receipt.amount)?.toLocaleString(undefined, { maximumFractionDigits: 3 })}
                {" "}
                {receipt.asset.symbol}
              </Text>
            </HStack>
          </HStack>
        </Flex>
      </Collapse>
    )
  }

  return(
    <>
      {displayPendingReceipts.length > 0
      ? <Flex flexDir={'column'} justifyContent="space-between" minHeight={'inherit'}>
          <Flex flexDir={'column'}>
            {displayPendingReceipts
            .map((receipt: MpReceiptProps, i) => {
              let showDate : boolean = false
              if(refDate.current.toDateString() !== new Date(receipt.createdAt).toDateString() || i === 0) {
                refDate.current = new Date(receipt.createdAt)
                showDate = true
              }
              return (
                <PendingReceipt
                  key={i}
                  showDate = {showDate}
                  receipt={receipt}
                />
              );
            })}
          </Flex>
        </Flex>
      : <Flex my={4} flexDir={'row'} alignItems={'center'} justifyContent='center' width={'full'}>
        Receord not found
      </Flex>
      }
    </>
  );
}

export default UserPendingReceipts;
