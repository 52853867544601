import { Flex, Text, Divider, Grid, GridItem } from "@chakra-ui/react";
import { ethers } from "ethers";
import React from "react";
import { useEffect, useState } from "react";
import { getMarkets } from "../services/mpMarketService";
import {
  BalanceByChainProps,
  BalanceBySymProps,
  MarketProps,
  PortfolioBalanceProps,
} from "../types";
import { chainIds } from "../utils/chainIds";
const MPVAULT_CONTRACT_ABI = require("../abi/mpVault.json")

const UserBalances = ({
  userAddress,
  displayChain,
  balancesBySym,
  balancesByChain,
}: {
  userAddress: string;
  displayChain: string;
  balancesBySym: BalanceBySymProps;
  balancesByChain: BalanceByChainProps;
}) => {
  const [chainBalances, setChainBalances] = useState<PortfolioBalanceProps[]>([]);
  const [pendingDeposits, setPendingDeposits] = useState<{[symbol:string]: string}>({});

  useEffect(() => {
    if (displayChain !== "Cross") {
      setChainBalances(balancesByChain[displayChain]);
    } else {
      let result: PortfolioBalanceProps[] = [];
      const symbols = Object.keys(balancesBySym);
      for (let i = 0; i < symbols.length; i++) {
        result.push({
          symbol: symbols[i],
          balance: balancesBySym[symbols[i]].balance,
          pendingTxnTotal: balancesBySym[symbols[i]].pendingTxnTotal,
          netBalance: Math.max(balancesBySym[symbols[i]].netBalance, 0),
          assetValue: Math.max(balancesBySym[symbols[i]].assetValue, 0),
        });
      }
      setChainBalances(result);
    }
  }, [displayChain, balancesBySym, balancesByChain]);

  //get pending deposit and withdrawals
  useEffect(() => {
    setPendingDeposits({})
    getTokenSymsAndPendingDeposits()
    .then((r) => {
      setPendingDeposits(r.pendingDeposits)
    })
  }, [displayChain])
  
  async function getTokenSymsAndPendingDeposits(){
    let mesMarkets : MarketProps[] = []
    let symbolList : string[] = []
    let pendingDeposits : {[symbol:string]: string} = {}
    //get market info first
    const chain = chainIds.find((chain) => chain.shortName === displayChain)
    if(chain){
      mesMarkets = await getMarkets(Number(chain?.chainId))
    }
    for(const balance of chainBalances){
      symbolList.push(balance.symbol)
    }
    for(const symbol of symbolList){
      const baseSymRec = mesMarkets.find((m) => m.baseSym === symbol && Number(m.chainId) === chain?.chainId)
      const quoteSymRec = mesMarkets.find((m) => m.quoteSym === symbol && Number(m.chainId) === chain?.chainId)
      if(baseSymRec){
        const basePendingDandW = await getPendingDepositAndWithdrawal(baseSymRec.baseContractAddr, baseSymRec.baseDecimal)
        if(basePendingDandW) {
          pendingDeposits[symbol] = basePendingDandW.pendingDeposit
        }
      } else if(quoteSymRec){
        const quotePendingDandW = await getPendingDepositAndWithdrawal(quoteSymRec.quoteContractAddr, quoteSymRec.quoteDecimal)
        if(quotePendingDandW) {
          pendingDeposits[symbol] = quotePendingDandW.pendingDeposit
        }
      }
    }
    return {
      pendingDeposits: pendingDeposits,
    }
  }

  async function getPendingDepositAndWithdrawal(erc20ContractAddr: string, decimal: number){
    if(displayChain === 'Cross') return
    try{
      const chain = chainIds.find((chain) => chain.shortName === displayChain)
      if(!chain) return
      const provider = new ethers.providers.JsonRpcProvider(chain.rpcUrl)
      const mpVaultContract = new ethers.Contract(
        chain.mpVaultContract,
        MPVAULT_CONTRACT_ABI,
        provider
      )
      const pendingDeposit = await mpVaultContract.getPendingDeposit(
        ethers.utils.getAddress(userAddress),
        erc20ContractAddr
      )
      return {
        pendingDeposit: ethers.utils.formatUnits(pendingDeposit, decimal),
      }
    } catch (err){
      console.log(err)
    }
  }

  return (
    <>
      <Grid
        border={'1px'}
        templateColumns={"repeat(6, 1fr)"}
        px={2}
        fontSize={"14px"}
        alignItems="center"
        textAlign={"start"}
        gap={2}
        whiteSpace="nowrap"
        width={"inherit"}
      >
        <GridItem colSpan={1}>
          <Text fontWeight={"extrabold"}>Symbol</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={"extrabold"}>Vault Balance</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={"extrabold"}>Pending Deposit</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={"extrabold"}>Open orders</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={"extrabold"}>Net Balance</Text>
        </GridItem>
        <GridItem>
          <Text fontWeight={"extrabold"}>{`Value(in USD)`}</Text>
        </GridItem>
        {chainBalances?.length > 0 ? (
          chainBalances.map((balance: any, i) => {
            const pendingDeposit = pendingDeposits[balance.symbol]
            return (
              <React.Fragment key={i}>
                <GridItem>
                  <Text fontWeight={"bold"}>{balance.symbol}</Text>
                </GridItem>
                <GridItem>
                  <Text>
                    {balance.balance?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight={"extrabold"}>
                    {pendingDeposit}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text fontStyle={"italic"}>
                    {"(" +
                      balance.pendingTxnTotal?.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      }) +
                      ")"}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight={"extrabold"}>
                    {balance.netBalance?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight={"extrabold"}>
                    ${" "}
                    {balance.assetValue?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                </GridItem>
                <GridItem colSpan={6}>
                  <Divider />
                </GridItem>
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
      </Grid>
      {chainBalances?.length > 0 ? (
        <></>
      ) : (
        <Flex
          my={4}
          flexDir={"row"}
          alignItems={"center"}
          justifyContent="center"
          width={"full"}
        >
          <Text>No record found</Text>
        </Flex>
      )}
    </>
  );
};

export default UserBalances;
