import { Divider, Flex, HStack, Select, Text, useInterval } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CachedOrderbooks from "../components/CachedOrderbooks";
import { useAuth } from "../context/useAuth";
import { getCachedOrderbook } from "../services/mpOrderbookService";
import { CachedObProps } from "../types";
import { chainIds } from "../utils/chainIds";

const Orderbooks = () => {
  const { accessToken } = useAuth();
  const [cachedOb, setCachedOb] = useState<CachedObProps[]>([]);
  const [filteredCachedOb, setFilteredCachedOb] = useState<CachedObProps[]>([]);
  const [assetPairId, setAssetPairId] = useState<string>("all");
  const [originatingChainId, setOriginatingChainId] = useState<string>("all");

  const _getCachedOrderbook = async() => {
    if(!accessToken) return
    const cachedOb = await getCachedOrderbook(accessToken)
    if(cachedOb) setCachedOb(cachedOb)
  }

  useEffect(() => {
    _getCachedOrderbook()
  }, []);

  useInterval(() => {
    _getCachedOrderbook();
  }, 60000);

  useEffect(() => {
    let originalOb : CachedObProps[] = cachedOb
    if(assetPairId !== "all"){
      originalOb = originalOb?.filter(ob => ob.key.split(":")[2] === assetPairId)
    }
    if(originatingChainId !== "all"){
      originalOb = originalOb?.filter(ob => ob.key.split(":")[3] === originatingChainId)
    }
    setFilteredCachedOb(originalOb)
  }, [cachedOb, assetPairId, originatingChainId])

  return(
    <Flex flexDir={'column'} gap={5} my={4}>
      <Text fontWeight={'extrabold'} color='cyan.600'>MES Orderbooks</Text>
      <HStack justifyContent={'space-between'} fontSize='14px' fontWeight={'bold'}>
        <Text whiteSpace={'nowrap'}>Choose Asset Pair:</Text>
        <Select onChange={e => setAssetPairId(e.target.value)} width='70%'>
          <option value="all"> All Pairs</option>
          <option value="1"> ETH-USDC</option>
          <option value="2"> USDT-USDC</option>
          <option value="3"> ETH-ETH</option>
          <option value="4"> USDC-USDC</option>
          <option value="5"> USDT-USDT</option>
        </Select>
      </HStack>
      <HStack justifyContent={'space-between'} fontSize='14px' fontWeight={'bold'}>
        <Text whiteSpace={'nowrap'}>Choose Chain:</Text>
        <Select onChange={e => setOriginatingChainId(e.target.value)} width='70%'>
          <option value="all"> All Chains</option>
          {chainIds.map((c) => {
            return(
              <option value={c.chainId}> {c.name} </option>
            )
          })}
        </Select>
      </HStack>
      <Divider/>
      {filteredCachedOb.map((ob) => {
        return(
          <CachedOrderbooks ob={ob}/>
        )
      })}
    </Flex>
  )
}

export default Orderbooks