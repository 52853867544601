import axios from 'axios'
import { backendPrefix } from '../utils/backendPrefix'

export async function getUserHooks(address: string) {
  return axios
  .get(backendPrefix + `/api/v1/hook?address=${address}`)
  .then(response => {
    return response.data
  })
  .catch(error => {
    console.log('Get user hooks error:', error)
  })
}