import { Tabs, TabList, Tab, TabPanels, TabPanel, Box, useColorModeValue, Flex, Divider, Text } from "@chakra-ui/react"
import { MpReceiptProps } from "../types"
import UserCompletedReceipts from "./UserCompletedReceipts"
import UserPendingReceipts from "./UserPendingReceipts"

export default function UserTransfers({
  displayChain,
  completedReceipts,
  pendingReceipts
} : {
  displayChain: string,
  completedReceipts : MpReceiptProps[]
  pendingReceipts : MpReceiptProps[]
}){
  return(
    <Flex bgColor={useColorModeValue("gray.50", "gray.700")} width="full" my={{base: 2, lg: 10}} rounded="lg">
      <Tabs align="center" variant='solid-rounded' colorScheme='twitter' p={{base: 2, lg: 4}} width='100%'>
        <TabList> 
          <Tab fontSize={{base: "12px", md: '14px'}}>
            <Flex flexDir={'row'} alignItems='center' gap={2}>
              <Text>Pending Receipts</Text>
              {pendingReceipts.length > 0
              ? <Box px={"10px"} py={"5px"} rounded={'lg'} bgColor={'gray.400'} color={'white'} fontSize={'10px'}> {pendingReceipts.length}</Box>
              : <></>
              }
            </Flex>
          </Tab>
          <Tab fontSize={{base: "12px", md: '14px'}}>Completed Receipts</Tab>
        </TabList>
        <Divider my={4}/>
        <TabPanels>
          <TabPanel minHeight={"calc(65vh)"}>
            <UserPendingReceipts displayChain = {displayChain} pendingReceipts={pendingReceipts}/>
          </TabPanel>
          <TabPanel minHeight={"calc(65vh)"}>
            <UserCompletedReceipts displayChain = {displayChain} completedReceipts={completedReceipts}/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}
