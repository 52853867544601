import { ChainProps } from "../types";

// testnet
// export const chainIds : ChainProps[] = [
//   {
//     name: "zkSync Alpha Testnet",
//     shortName: "zkSync",
//     chainId: 280,
//     token: 'ETH',
//     lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/zksync_logo.svg",
//     darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/zksync_logo.svg",
//     rpcUrl: "https://zksync2-testnet.zksync.dev",
//     blockExplorerUrl: "https://scan-v2.zksync.dev",
//     mpVaultContract: "0x2dD687E37323BD71A3b31b28Dc6dEF2f2Addd9b5"
//   },
//   {
//     name: "Arbitrum Nitro",
//     shortName: "Arbitrum",
//     chainId: 421613,
//     token: "ETH",
//     lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/arbitrum_logo.svg",
//     darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/arbitrum_logo.svg",
//     rpcUrl: "https://goerli-rollup.arbitrum.io/rpc",
//     blockExplorerUrl: "https://goerli.arbiscan.io",
//     mpVaultContract: "0x669AD667907F604E9C6B4B8f025723F47AA9eA60"
//   },
//   {
//     name: "Optimism Goerli",
//     shortName: "Optimism",
//     chainId: 420,
//     token: "ETH",
//     lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/optimism_logo.svg",
//     darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/optimism_logo.svg",
//     rpcUrl: "https://goerli.optimism.io",
//     blockExplorerUrl: "https://goerli-optimism.etherscan.io",
//     mpVaultContract: "0x8f3Ddd0FBf3e78CA1D6cd17379eD88E261249B52"
//   },
//   {
//     name: "Linea Testnet",
//     shortName: "Linea",
//     chainId: 59140,
//     token: "crETH",
//     lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/linea_logo_black.svg",
//     darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/linea_logo_white.svg",
//     rpcUrl: "https://rpc.goerli.linea.build",
//     blockExplorerUrl: "https://explorer.goerli.linea.build",
//     mpVaultContract: "0x8f3Ddd0FBf3e78CA1D6cd17379eD88E261249B52"
//   },
//   {
//     name: 'Polygon zkEVM Testnet',
//     shortName: "Polygon",
//     chainId: 1442,
//     token: 'ETH',
//     lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/polygon_zkevm_logo.svg",
//     darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/polygon_zkevm_logo.svg",
//     rpcUrl: 'https://rpc.public.zkevm-test.net',
//     blockExplorerUrl: 'https://testnet-zkevm.polygonscan.com',
//     mpVaultContract: "0xb77c5A8426Ee2af0Ef2A69FE1202DbAfFD0fBddF"
//   },
//   {
//     name: 'Scroll Alpha Testnet',
//     shortName: "Scroll",
//     chainId: 534353,
//     token: 'ETH',
//     lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/scroll_logo.svg",
//     darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/scroll_logo.svg",
//     rpcUrl: 'https://alpha-rpc.scroll.io/l2',
//     blockExplorerUrl: 'https://blockscout.scroll.io',
//     mpVaultContract: "0x8f3Ddd0FBf3e78CA1D6cd17379eD88E261249B52"
//   }, {
//     name: 'Base Goerli',
//     shortName: "Base",
//     chainId: 84531,
//     token: 'ETH',
//     lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/base_logo.svg",
//     darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/base_logo.svg",
//     rpcUrl: '	https://goerli.base.org',
//     blockExplorerUrl: 'https://goerli.basescan.org',
//     mpVaultContract: "0x8f3Ddd0FBf3e78CA1D6cd17379eD88E261249B52"
//   },
// ];

// mainnet

export const chainIds : ChainProps[] = [
  {
    name: "zkSync Era",
    shortName: "zkSync",
    chainId: 324,
    token: 'ETH',
    lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/zksync_logo.svg",
    darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/zksync_logo.svg",
    rpcUrl: "https://mainnet.era.zksync.io",
    blockExplorerUrl: "https://explorer.zksync.io/",
    mpVaultContract: "0xCD0E8Fb86fb6FC5591Bc0801490d33d515Ba613F"
  },
  {
    name: "Arbitrum One",
    shortName: "Arbitrum",
    chainId: 42161,
    token: "ETH",
    lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/arbitrum_logo.svg",
    darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/arbitrum_logo.svg",
    rpcUrl: "https://arbitrum-mainnet.infura.io/v3/f2d438a1e02f46e2aef9836fdd51ae7d",
    blockExplorerUrl: "https://arbiscan.io/",
    mpVaultContract: "0x052848c0E8F73BBCf53001496b2C78b02efE933b"
  },
  {
    name: "Optimism",
    shortName: "Optimism",
    chainId: 10,
    token: "ETH",
    lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/optimism_logo.svg",
    darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/optimism_logo.svg",
    rpcUrl: "https://optimism-mainnet.infura.io/v3/f2d438a1e02f46e2aef9836fdd51ae7d",
    blockExplorerUrl: "https://optimistic.etherscan.io/",
    mpVaultContract: "0x052848c0E8F73BBCf53001496b2C78b02efE933b"
  },
  {
    name: "Linea",
    shortName: "Linea",
    chainId: 59144,
    token: 'ETH',
    lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/linea_logo_black.svg",
    darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/linea_logo_white.svg",
    rpcUrl: 'https://linea-mainnet.infura.io/v3/5e05fc7d66e249f0a86a49b0cbe16936',
    blockExplorerUrl: "https://lineascan.build",
    mpVaultContract: "0x052848c0E8F73BBCf53001496b2C78b02efE933b"
  },
  {
    name: "Ethereum Mainnet",
    shortName: "Ethereum",
    chainId: 1,
    token: 'ETH',
    lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/token_logos/eth_logo.svg",
    darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/token_logos/eth_logo.svg",
    rpcUrl: "https://mainnet.infura.io/v3/f2d438a1e02f46e2aef9836fdd51ae7d",
    blockExplorerUrl: "https://etherscan.io",
    mpVaultContract: "0xbfc0e7E964F9445Aab8E3F76101FfBdEF3EDDd96"
  },
  {
    name: "Base",
    shortName: "Base",
    chainId: 8453,
    token: "ETH",
    lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/base_logo.svg",
    darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/base_logo.svg",
    rpcUrl: "https://mainnet.base.org",
    blockExplorerUrl: "https://basescan.org",
    mpVaultContract: "0x052848c0E8F73BBCf53001496b2C78b02efE933b"
  },
  {
    name: "Manta Pacific",
    shortName: "Manta",
    chainId: 169,
    token: "ETH",
    lightLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/manta_logo.svg",
    darkLogo: "https://s3.ap-southeast-1.amazonaws.com/mesprotocol.com/mes_images/chain_logos/manta_logo.svg",
    rpcUrl: "https://pacific-rpc.manta.network/http",
    blockExplorerUrl: "https://pacific-explorer.manta.network",
    mpVaultContract: "0x052848c0E8F73BBCf53001496b2C78b02efE933b"
  },
];
