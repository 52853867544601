import { useColorModeValue } from "@chakra-ui/react";

interface DepthVisualizerProps {
  depth: number;
  orderType: string;
}

export default function DepthVisualizer ({depth, orderType }: DepthVisualizerProps ) {
  const DepthVisualizerColors = {
    BIDS: useColorModeValue("#D5F0CE", "#113534"),
    ASKS: useColorModeValue("#F9CBCB", "#3d1e28")
  };

  return(
    <div style={{
      backgroundColor: `${orderType === 'bid' ? DepthVisualizerColors.BIDS : DepthVisualizerColors.ASKS}`,
      height: "1.250em",
      width: `${depth}%`,
      position: "relative",
      top: 21,
      marginTop: -24,
      zIndex: 1,
    }} />
  )
};
