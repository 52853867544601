import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Button,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Text
} from "@chakra-ui/react";
import { changePassword } from "../services/authService";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState('');
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handlePasswordChange = async (e: any) => {
    e.preventDefault();
    try {
      const response = await changePassword(username, oldPassword, newPassword);
      if (response) {
        if (response.status === 200) {
          toast(
            `Password updated. Redirecting to login page...`, {
              type: "success",
              theme: "dark",
              autoClose: 5000,
              hideProgressBar: true,
              pauseOnHover: false,
              position: "top-right",
              toastId: "changepassword",
            }
          )
          navigate('/login', { replace: true });
        } else {
          setMessage('Update Password error, please try again');
        }
      }
    } catch (error) {
      setMessage("Error, please contract dev");
    }
  };

  return (
    <Container mt={10} mb={20} centerContent={true}>
      <Box
        minH={"400px"}
        maxW={"400px"}
        w={"full"}
        bg={useColorModeValue("white", "gray.900")}
        rounded={"lg"}
        boxShadow={"2xl"}
        overflow={"hidden"}
      >
        <Box p={6}>
          <form onSubmit={handlePasswordChange}>
          <FormControl id="username" isRequired>
              <FormLabel fontSize='sm' mt={2}>Username</FormLabel>
              <Input
                type="text"
                name="username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel fontSize='sm' mt={2}>Old Password</FormLabel>
              <Input
                type="password"
                name="password"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel fontSize='sm' mt={2}>New Password</FormLabel>
              <Input
                type="password"
                name="password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel fontSize='sm' mt={2}>Confirm New Password</FormLabel>
              <Input
                type="password"
                name="password"
                onChange={(e) => {
                  if(e.target.value !== newPassword) {
                    setMessage('Password does not match')
                  } else {
                    setMessage('')
                  }
                }}
              />
            </FormControl>
            <Text 
              textAlign='end'
              fontSize='xs'
              color = 'blue.100'
              my={4} 
              onClick={() => navigate('/login')}
              cursor='pointer'
              > Return</Text>
            <Center mt={4}>
              <Button onClick={handlePasswordChange}>
                Change
              </Button>
            </Center>
          </form>

          {message && (
            <Alert mt={5} status="error">
              <AlertIcon />
              <Box flex="1">
                <AlertTitle>Error:</AlertTitle>
                <AlertDescription display="block">{message}</AlertDescription>
              </Box>
              <CloseButton
                position="absolute"
                right="8px"
                top="8px"
                onClick={() => setMessage("")}
              />
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ChangePassword;
