import {
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  useColorModeValue,
  Flex,
  TabPanels,
} from "@chakra-ui/react";
import { OrderRowsProps } from "../types";

export default function OrderHistory({
  openOrderRows,
  historicOrderRows,
}: {
  openOrderRows: OrderRowsProps[];
  historicOrderRows: OrderRowsProps[];
}) {
  const selectedTabBgColor = useColorModeValue("gray.100", "gray.700");
  const selectedTabFontColor = useColorModeValue("cyan.600", "cyan.400");
  const tabBgColor = useColorModeValue("gray.200", "gray.800");

  const OrderTable = ({
    orderRows,
    priceType,
    isOpenOrderTab,
  }: {
    orderRows: OrderRowsProps[];
    priceType: string;
    isOpenOrderTab: boolean;
  }) => {
    return (
      <TabPanel
        py={0}
        px={1.5}
        mt={2}
        flexDir={"column"}
        display={'flex'}
        justifyContent="space-between"
        flex={1}
        width="full"
        whiteSpace={"nowrap"}
      >
        <table style={{ fontSize: "13px" }}>
          <thead>
            <tr
              style={{
                lineHeight: "30px",
                backgroundColor: useColorModeValue("#F2F2F2", "#121723"),
              }}
            >
              <th>ID</th>
              <th>Pair</th>
              <th>Chain</th>
              <th>Side</th>
              <th>Route</th>
              <th>Type</th>
              <th>Status</th>
              <th>Filled Amt</th>
              <th>Received Amt</th>
              <th>{priceType === "price" ? "Price" : "Filled Price"}</th>
              {isOpenOrderTab ? <></> : <th>Fees</th>}
              <th>{isOpenOrderTab ? "Expiry" : "Timestamp"}</th>
            </tr>
          </thead>
          {orderRows?.map((order: any, i) => {
            const timestamp = new Date(order.createdAt);
            return (
              <tbody key={i}>
                <tr key={i}>
                  <td style={{ textAlign: "center" }}>{order.id}</td>
                  <td style={{ textAlign: "center" }}>{order.assetPair}</td>
                  <td style={{ textAlign: "center" }}>
                    {<Text fontWeight={"bold"}>{order.chain}</Text>}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      color:
                        order.orderDirection === "Buy" ? "#66BB6A" : "#EF5350",
                    }}
                  >
                    {order.orderDirection}
                  </td>
                  <td style={{ textAlign: "center" }}>{order.route}</td>
                  <td style={{ textAlign: "center" }}>{order.type}</td>
                  {order.status === "Open" ? (
                    <td
                      style={{
                        textAlign: "center",
                        color: useColorModeValue("#43A047", "#66BB6A"),
                      }}
                    >
                      {order.status}
                    </td>
                  ) : order.status === "Partial" ? (
                    <td
                      style={{
                        textAlign: "center",
                        color: useColorModeValue("#FDD835", "#FFEE58"),
                      }}
                    >
                      {order.status}
                    </td>
                  ) : order.status === "Cancelled" ? (
                    <td
                      style={{
                        textAlign: "center",
                        color: useColorModeValue("#E53935", "#EF5350"),
                      }}
                    >
                      {order.status}
                    </td>
                  ) : order.status === "Filled" ? (
                    <td
                      style={{
                        textAlign: "center",
                        color: useColorModeValue("#26C6DA", "#80DEEA"),
                      }}
                    >
                      {order.status}
                    </td>
                  ) : order.status === "Inactive" ? (
                    <td
                      style={{
                        textAlign: "center",
                        color: useColorModeValue("#718096", "#718096"),
                      }}
                    >
                      {order.status}
                    </td>
                  ) : (
                    <td
                      style={{
                        textAlign: "center",
                        color: useColorModeValue("#00ACC1", "#26C6DA"),
                      }}
                    >
                      {order.status}
                    </td>
                  )}
                  <td style={{ textAlign: "center" }}>
                    {order.type === "Limit"
                      ? order.orderDirectionDisplayId === 0
                        ? //limit buy
                          `${Number(order.filledBaseAmount)} / ${Number(
                            order.receiveAmount
                          )} ${order.baseSym}`
                        : //limit sell
                          `${Number(order.filledBaseAmount)} / ${Number(
                            order.payAmount
                          )} ${order.baseSym}`
                      : order.orderDirectionDisplayId === 0
                      ? //market buy
                        order.payAmount === 0
                        ? //by quantity
                          `${Number(order.filledBaseAmount)} / ${Number(
                            order.receiveAmount
                          )} ${order.baseSym}`
                        : //by amount
                          `${Number(order.filledQuoteAmount)} / ${Number(
                            order.payAmount
                          )} ${order.quoteSym}`
                      : //market sell
                      order.receiveAmount === 0
                      ? //by quantity
                        `${Number(order.filledBaseAmount)} / ${Number(
                          order.payAmount
                        )} ${order.baseSym}`
                      : //by amount
                        `${Number(order.filledQuoteAmount)} / ${Number(
                          order.receiveAmount
                        )} ${order.quoteSym}`}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {order.orderDirectionDisplayId === 0
                      ? `${Number(order.filledBaseAmount)}`
                      : `${Number(order.filledQuoteAmount)}`
                    }
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {order.type === "Market" && isOpenOrderTab
                      ? "-"
                      : order[priceType]}
                  </td>
                  {isOpenOrderTab ? (
                    <></>
                  ) : (
                    <td style={{ textAlign: "center" }}>
                      {parseFloat(order.fees?.toFixed(8))}
                      {order.orderDirectionDisplayId === 0
                        ? order.baseSym
                        : order.quoteSym}
                    </td>
                  )}
                  {isOpenOrderTab ? (
                    <td style={{ textAlign: "center" }}>
                      {" "}
                      {order.expiryDate > 0
                        ? new Date(order.expiryDate).toLocaleDateString()
                        : "-"}
                    </td>
                  ) : (
                    <td style={{ textAlign: "center" }}>
                      {" "}
                      {timestamp?.toLocaleString()}
                    </td>
                  )}
                </tr>
              </tbody>
            );
          })}
        </table>
      </TabPanel>
    );
  };

  return (
    <Tabs
      variant="enclosed-colored"
      display="flex"
      flexDir={"column"}
      flex={1}
    >
      <TabList justifyContent={"space-between"}>
        <Flex flexDir={"row"}>
          <Tab
            width={{ base: "120px", lg: "150px" }}
            whiteSpace="nowrap"
            fontSize={{ base: "12px", lg: "13px" }}
            bgColor={tabBgColor}
            _selected={{
              color: selectedTabFontColor,
              bg: selectedTabBgColor,
              fontWeight: "bold",
            }}
            roundedTop="md"
            border={0}
          >
            Open Orders
          </Tab>
          <Tab
            width={{ base: "120px", lg: "150px" }}
            whiteSpace="nowrap"
            fontSize={{ base: "12px", lg: "13px" }}
            bgColor={tabBgColor}
            _selected={{
              color: selectedTabFontColor,
              bg: selectedTabBgColor,
              fontWeight: "bold",
            }}
            roundedTop="md"
            border={0}
          >
            Order History
          </Tab>
        </Flex>
      </TabList>
      <TabPanels>
        <OrderTable
          orderRows={openOrderRows}
          priceType={"price"}
          isOpenOrderTab={true}
        />
        <OrderTable
          orderRows={historicOrderRows}
          priceType={"filledPrice"}
          isOpenOrderTab={false}
        />
      </TabPanels>
    </Tabs>
  );
}
