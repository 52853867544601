import "./App.css";
import {
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container } from "@chakra-ui/react";
import Navbar from "./pages/Navbar";
import Login from "./pages/Login";
import Home from './pages/Home';
import {RequireModAuth, RequireTeamAuth} from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import User from "./pages/User";
import Order from './pages/Order';
import Epochs from './pages/Epochs';
import Orderbooks from './pages/Orderbooks';
import 'react-toastify/dist/ReactToastify.css';
import ChangePassword from "./components/ChangePassword";
import Jobs from "./pages/Jobs";

function App() {
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
      <Navbar/>
      <Container maxW = 'container.xl' height='max-content'>
        <Routes>
          <Route path = '/' element = {<Home/>}/>
            <Route path="/login" element = {<Login/>}/>
            <Route path='/changepw' element = {<ChangePassword/>}/>
            <Route element = {<PersistLogin/>}>
              <Route element = {<RequireModAuth/>}>
                <Route path ='/user' element = {<User/>}/>
                <Route path ='/orderbooks' element ={<Orderbooks/>}/>
                <Route element = {<RequireTeamAuth/>}>
                  <Route path ='/orders' element = {<Order/>}/>
                  <Route path ='/jobs' element= {<Jobs/>}/>
                  <Route path ='/epochs' element= {<Epochs/>}/>
                </Route>
              </Route>
            </Route>
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
