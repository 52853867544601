import { Outlet } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { accessToken } = useAuth();

  useEffect(() => {
    const verifyRefreshToken = async() => {
      try {
        await refresh();
      } catch (err) {
        console.log(err)
      } finally{
        setIsLoading(false)
      }
    }
    !accessToken? verifyRefreshToken() : setIsLoading(false)
  }, [])

  return(
    <>
      {isLoading
        ? <Spinner/>
        : <Outlet/>
      }
    </>
  )
}

export default PersistLogin