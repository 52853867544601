import axios from 'axios'
import { backendPrefix } from '../utils/backendPrefix'

export async function getSettledOrders(token:string, address:string, startDate:string, endDate:string) {
  let paramsInput:{[key:string]:any} = {address:address, startDate:startDate, endDate:endDate}
  return axios
  .get(backendPrefix + '/api/v1/orders/closed', {headers: {
        'Authorization' : `Bearer ${token}`
      }, params:paramsInput})
  .then(response => {
    return response.data
  })
  .catch(error => {
    console.log('Fetch all accounts error:', error)
  })
}
