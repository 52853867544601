import { useLocation, Navigate, Outlet} from 'react-router-dom';
import { useAuth } from '../context/useAuth';

export function RequireModAuth() {
  const location = useLocation();
  const { accessToken, role } = useAuth();

  return(
    accessToken && role === "moderator" || "team"
      ? <Outlet/>
      : <Navigate to = '/login' state={{from: location}} replace />
  )
}

export function RequireTeamAuth() {
  const location = useLocation();
  const { accessToken, role } = useAuth();

  return(
    accessToken && role === "team"
      ? <Outlet/>
      : <Navigate to = '/login' state={{from: location}} replace />
  )
}
