import { createContext, useState, useContext } from "react";
import { AuthContextProps } from "../types";

const initialValue = {
  accessToken: null,
  setAccessToken: () => {},
  role: null,
  setRole: () => {}
}

const AuthContext = createContext<AuthContextProps>(initialValue)

export const AuthProvider = ({ children }: any) => {
  const [accessToken, setAccessToken] = useState(null);
  const [role, setRole] = useState("user")

  return(
    <AuthContext.Provider value={{accessToken, setAccessToken, role, setRole}}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export default AuthContext;