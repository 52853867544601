import axios from 'axios'
import { backendPrefix } from '../utils/backendPrefix'
import { toast } from 'react-toastify'

export async function getUserCount() {
  return axios
  .get(backendPrefix + '/api/v1/accounts/count')
  .then(response => {
    return response.data
  })
  .catch(error => {
    console.log('Fetch all accounts error:', error)
  })
}

export async function getUserBalance(address: string){
  return axios
  .get(backendPrefix + `/api/v1/accounts/${address}/balances`)
  .then(response => {
    return response.data
  })
  .catch(error => {
    toast(`Cannot find user: ${address}`, {
      type: "error",
      theme: "dark",
      autoClose: 5000,
      hideProgressBar: true,
      pauseOnHover: false,
      position: "top-right",
      toastId: "checkBalanceFailed",
    });
    console.log('Fetch user info error: ', error)
  })
}

export async function getUserOrder(address:string){
  return axios
  .get(backendPrefix + `/api/v1/accounts/${address}/orders`)
  .then(response => {
    return response.data
  })
  .catch(error => {
    console.log('Fetch user order error : ', error)
  })
}

export async function getUserReceipts(address:string){
  return axios
  .get(backendPrefix + `/api/v1/accounts/${address}/transfers`)
  .then(response => {
    return response.data
  })
  .catch(error => {
    console.log('Fetch user order error : ', error)
  })
}
