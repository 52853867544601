import * as ethers from 'ethers'

export const roundingConstant = 6

export const addFixed = (a:number, b:number) => {
  const aFixed = ethers.FixedNumber.from(a.toFixed(roundingConstant));
  const bFixed = ethers.FixedNumber.from(b.toFixed(roundingConstant));
  const result = parseFloat(aFixed.addUnsafe(bFixed)._value)
  return parseFloat(result.toFixed(roundingConstant))
}

export const subFixed = (a:number, b:number) => {
  const aFixed = ethers.FixedNumber.from(a.toFixed(roundingConstant));
  const bFixed = ethers.FixedNumber.from(b.toFixed(roundingConstant));
  const result = parseFloat(aFixed.subUnsafe(bFixed)._value)
  return parseFloat(result.toFixed(roundingConstant))
}

export const mulFixed = (a:number, b:number) => {
  const aFixed = ethers.FixedNumber.from(a.toFixed(roundingConstant));
  const bFixed = ethers.FixedNumber.from(b.toFixed(roundingConstant));
  const result = parseFloat(aFixed.mulUnsafe(bFixed)._value)
  return parseFloat(result.toFixed(roundingConstant))
}

export const divFixed = (a:number, b:number) => {
  const aFixed = ethers.FixedNumber.from(a.toFixed(roundingConstant));
  const bFixed = ethers.FixedNumber.from(b.toFixed(roundingConstant));
  const result = parseFloat(aFixed.divUnsafe(bFixed)._value)
  return parseFloat(result.toFixed(roundingConstant))
}

export const addNumsFixed = (numArr: number[]) => {
  const initialValue = 0;
  const addNumArr = numArr.reduce(
    (prev, curr) => {
      const prevFixed = ethers.FixedNumber.from(prev.toFixed(roundingConstant))
      const currFixed = ethers.FixedNumber.from(curr.toFixed(roundingConstant))
      const result = parseFloat(prevFixed.addUnsafe(currFixed)._value)
      return parseFloat(result.toFixed(roundingConstant))
    },
    initialValue
  );
  return addNumArr
}

export const subNumsFixed = (numArr: number[]) => {
  const subNumArr = numArr.reduce(
    (prev, curr) => {
      const prevFixed = ethers.FixedNumber.from(prev.toFixed(roundingConstant))
      const currFixed = ethers.FixedNumber.from(curr.toFixed(roundingConstant))
      const result = parseFloat(prevFixed.subUnsafe(currFixed)._value)
      return parseFloat(result.toFixed(roundingConstant))
    });
  return subNumArr
}

export const countDecimals = (value:number) => {
  if (Math.floor(value.valueOf()) === value.valueOf()) return 0;
    var str = value.toString();
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
        return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
        return str.split(".")[1].length || 0;
    }
    return str.split("-")[1] || 0;
}

export const roundDownTo = (value:number, decimal:number) => {
  decimal = decimal || 0
  let result = (Math.floor(value * Math.pow(10, decimal))) / Math.pow(10, decimal)
  //Have to use toFixed to make sure the decimal place is correct
  //because when we do division, javascript will still give us unrounded answers
  result = parseFloat(result.toFixed(decimal))
  if (countDecimals(result) > decimal) throw new Error("Failed to roundDownTo")
  return result
}

export const roundUpTo = (value:number, decimal:number) => {
  decimal = decimal || 0
  let result = (Math.floor(value * Math.pow(10, decimal)) + 1) / Math.pow(10, decimal)
  //Have to use toFixed to make sure the decimal place is correct
  //because when we do division, javascript will still give us unrounded answers
  result = parseFloat(result.toFixed(decimal))
  if (countDecimals(result) > decimal) throw new Error("Failed to roundDownTo")
  return result
}

export const posOrNeg = (value: number) => {
  const signed = Math.sign(value)
  if(signed < 0) return 1
  else return 0
}

export const isExceedMaxTolerance = (a:number, b:number, maxDecimal:number) => {
  const diff = subFixed(a, b)
  const maxToleranceValue = divFixed(1, 10 ** maxDecimal)
  if(Math.abs(diff) < maxToleranceValue) return false
  else return true
}

export const convertScientificNotationNumber = (value:number) => {
  const decimalsPart = value?.toString()?.split('.')?.[1] || value?.toString()?.split('.')?.[0];
  const eDecimals = Number(decimalsPart?.split('e-')?.[1]) || 0;
  const countOfDecimals = decimalsPart.length + eDecimals;
  return Number(value).toFixed(countOfDecimals);
};
