import axios from 'axios'
import { backendPrefix } from '../utils/backendPrefix'

export async function login(username: string, password: string) {
  return axios
  .post(backendPrefix + '/api/v1/admin/signin', {
    username,
    password
  }, {
    withCredentials: true,
  })
  .then(response => {
    return {
      status: response.status,
      message: 'success',
      token: response?.data?.token,
      role: response?.data?.role
    }
  })
  .catch(error => {
    console.log('Login error:', error)
    return {
      status: error.status,
      message: error.message,
      token: null,
      role: null
    }
  })
}

export async function logout() {
  return axios
  .post(backendPrefix + '/api/v1/admin/logout', null, {
    withCredentials: true
  })
  .catch(error => console.log('Logout error:', error))
}

export async function changePassword(username: string, oldPassword:string, newPassword:string) {
  return axios
  .post(backendPrefix + '/api/v1/admin/changepassword',
  {
    username,
    oldPassword,
    newPassword
  }, {
    withCredentials: true
  })
  .then(response => {
    return {
      status: response.status,
      message: 'success',
    }
  })
  .catch(error => {
    console.log('Change password error:', error)
    return {
      status: error.status,
      message: error.response.data,
    }
  })
}
