import { Flex, Grid, Text } from "@chakra-ui/react";
import { CachedObProps, ObRowProps } from "../types";
import DepthVisualizer from "../components/DepthVisualizer";
import React from "react";

const CachedOrderbooks = ({ ob }: { ob: CachedObProps }) => {
  const parsedObRows: ObRowProps[] = JSON.parse(ob.cachedOrderbook);

  function aggregateSize(orders: {price: number, size: number}[]){
    return orders?.reduce((accumulator:any,currLv:any) => {
      return accumulator + currLv.size
      }, 0
    )
  }
  const direction = ob.key.split(":")[5]
  const totalDepth = aggregateSize(parsedObRows)
  const orderRows = parsedObRows?.map((order:any) => {
    const total = order.size * order.price;
    const depth = order.size / totalDepth * 100
    return {
      price: order.price,
      size: order.size,
      total: total,
      orderIds: order.orderIds,
      depth: depth
    }
  })

  return (
    <Flex flexDir={"column"}>
      <Text fontSize={'14px'} fontWeight='bold'>{ob.key}</Text>
      <Grid
        templateColumns="repeat(4, 1fr)"
        px={2}
        height="22px"
        fontSize={"13px"}
        alignItems={"center"}
        color={"#98a6af"}
        backgroundColor={"#121723"}
        whiteSpace={"nowrap"}
      >
        <Text>Price</Text>
        <Text justifySelf={"center"} textAlign="center">Size</Text>
        <Text justifySelf={"center"} textAlign="center">Total</Text>
        <Text
          justifySelf={"flex-end"}
          textAlign="center"
        >{`OrderIds length`}</Text>
      </Grid>
      {orderRows?.map((level) => {
        return (
          <React.Fragment>
            <DepthVisualizer depth = {level.depth} orderType = {direction}/>
            <Grid
              templateColumns="repeat(4, 1fr)"
              px={2}
              alignItems={"center"}
              height="22px"
              fontSize={"13px"}
            >
              <Flex>
                <Text zIndex={1} className="price">
                  {level.price}
                </Text>
              </Flex>
              <Flex justifySelf={"center"}>
                <Text zIndex={1}> {level.size} </Text>
              </Flex>
              <Flex justifySelf={"center"}>
                <Text zIndex={1}> {level.total?.toFixed(3)} </Text>
              </Flex>
              <Flex justifySelf={"flex-end"}>
                <Text zIndex={1}> {level.orderIds.length} </Text>
              </Flex>
            </Grid>
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

export default CachedOrderbooks;
