import axios from 'axios'
import { backendPrefix } from '../utils/backendPrefix'

export async function getJobList(token: string){
  return axios
  .get(backendPrefix + '/api/v1/jobs', {
    headers: {
      'Authorization' : `Bearer ${token}`
    },
  })
  .then(response => {
    if(!response.data || response.data.length === 0) return
    const jobs = response.data
    for(let i in jobs){
      const date = new Date(jobs[i].nextRun)
      jobs[i].nextRun = `${date.toLocaleDateString()} ${date.toLocaleTimeString()} HKT`
    }
    return jobs
  })
  .catch(err => {
    return
  })
}
export async function removeJob(jobName: string, token: string){
  return axios
  .delete(backendPrefix + `/api/v1/jobs/removejob`,
  {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    withCredentials: true,
    params: {
      jobName: jobName
    }
  })
  .then(response => {
    return {
      status: response.status,
      message: 'success'
    }
  })
  .catch(error => {
    console.log('Remove job error: ', error)
    return {
      status: error.status,
      message: error?.response?.data
    }
  })
}

export async function pauseOrResumeJob(jobName: string, token: string){
  return axios
  .put(backendPrefix + `/api/v1/jobs/pausejob`, null ,{
    headers: {
      'Authorization': `Bearer ${token}`
    },
    withCredentials: true,
    params: {
      jobName: jobName
    }
  })
  .then(response => {
    return {
      status: response.status,
      message: 'success'
    }
  })
  .catch(error => {
    console.log('Pause/Resume job error: ', error)
    return {
      status: error.status,
      message: error?.response?.data
    }
  })
}

export async function pauseAllJobs(token: string){
  return axios
  .put(backendPrefix + `/api/v1/jobs/pauseAll`, null ,{
    headers: {
      'Authorization': `Bearer ${token}`
    },
    withCredentials: true,
  })
  .then(response => {
    return {
      status: response.status,
      message: 'success'
    }
  })
  .catch(error => {
    console.log('Pause all job error: ', error)
    return {
      status: error.status,
      message: error?.response?.data
    }
  })
}

export async function resumeAllJobs(token: string){
  return axios
  .put(backendPrefix + `/api/v1/jobs/resumeAll`, null ,{
    headers: {
      'Authorization': `Bearer ${token}`
    },
    withCredentials: true,
  })
  .then(response => {
    return {
      status: response.status,
      message: 'success'
    }
  })
  .catch(error => {
    console.log('Resume all job error: ', error)
    return {
      status: error.status,
      message: error?.response?.data
    }
  })
}
