import { Tabs, TabList, Tab, Box, Text, Flex, Divider, TabPanels, TabPanel } from "@chakra-ui/react";
import { UserHookProps } from "../types";
import UserHookRecords from "./UserHookRecords";

export default function UserHooks({
  openHooks,
  closedHooks
} : {
  openHooks: UserHookProps[] | undefined
  closedHooks: UserHookProps[] | undefined
}){
  return(
    <Tabs align="center" variant='solid-rounded' colorScheme='twitter' p={{base: 2, lg: 4}} width='100%'>
      <TabList> 
        <Tab fontSize={{base: "12px", md: '14px'}}>
          <Flex flexDir={'row'} alignItems='center' gap={2}>
            <Text>Pending Transfers</Text>
            {openHooks && openHooks.length > 0
            ? <Box px={"10px"} py={"5px"} rounded={'lg'} bgColor={'gray.400'} color={'white'} fontSize={'10px'}> {openHooks.length}</Box>
            : <></>
            }
          </Flex>
        </Tab>
        <Tab fontSize={{base: "12px", md: '14px'}}>Completed Transfers</Tab>
      </TabList>
      <Divider my={4}/>
      <TabPanels>
        <TabPanel minHeight={"calc(65vh)"}>
          {openHooks &&
            <UserHookRecords hooks={openHooks} isOpenOrderTab={true}/>
          }
        </TabPanel>
        <TabPanel minHeight={"calc(65vh)"}>
          {closedHooks &&
            <UserHookRecords hooks={closedHooks} isOpenOrderTab={false}/>
          }
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
